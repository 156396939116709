import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { width, height } from 'styled-system';

import { theme as Rtheme } from '../Utils/theme';
import { ErrorUI } from './ErrorUI';
import { IconNew } from '../Atoms';

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const StyledIcon = styled(IconNew)`
  position: absolute;
  right: calc(100% - 98%);
  top: calc(100% - 70%);
  font-size: 20px;
  pointer-events: none;
`;

const StyledSelect = styled.select`
  color: ${Rtheme.colors.fontColor};
  cursor: pointer;
  width: 100%;
  min-width: 98px;
  border-color: ${Rtheme.colors.borderColor};
  border-radius: ${p => (p.removeRightBorder ? '5px 0px 0px 5px' : '5px')};
  border-style: solid;
  border-width: 1px;
  outline: 0;
  box-sizing: border-box;
  border-right-color: ${p => p.removeRightBorder && 'transparent'};
  border-top-right: 0px;
  padding: 10px 24px 9px 10px;
  font-size: 15px;
  background-color: white;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
  ${width}
  ${height}
`;

export const DefaultSelect = props => {
  const {
    id,
    meta,
    input: { name, onChange, value },
    isDisabled,
    options,
    height,
    removeRightBorder,
    iconColor,
    fontSize,
  } = props;

  const handleChange = value => {
    const selectedOption = options.filter(item => item.value == value);
    onChange(selectedOption[0]);
  };

  return (
    <>
      <Wrapper width={props.width}>
        <StyledIcon
          isCursor
          className="material-icons"
          color={iconColor}
          fontSize={fontSize}
        >
          expand_more
        </StyledIcon>
        <StyledSelect
          id={id}
          name={name}
          height={height}
          value={value.value}
          onChange={e => handleChange(e.target.value)}
          disabled={isDisabled}
          removeRightBorder={removeRightBorder}
        >
          {options.map(item => (
            <option value={item.value}>{item.label}</option>
          ))}
        </StyledSelect>
      </Wrapper>
      <ErrorUI meta={meta} />
    </>
  );
};

DefaultSelect.defaultProps = {
  borderRadius: '5px',
  height: '45px',
  width: '100%',
  borderOnFocus: true,
  meta: { error: undefined, touched: undefined },
  input: {
    onChange: () => {},
  },
  iconColor: '#68878d',
  fontSize: '18px',
  options: [],
};

DefaultSelect.propTypes = {
  borderRadius: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  borderOnFocus: PropTypes.bool,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  options: PropTypes.array,
};
