import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactCalendar from 'react-calendar';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import './override.css';

const moment = extendMoment(Moment);

const Wrapper = styled.div`
  position: relative;
  width: ${props => props.width || '320px'};
  .react-calendar {
    box-shadow: ${props =>
      props.boxShadow || '0 10px 20px 0 rgba(23, 114, 112, 0.2)'};
    width: ${props => props.width || '300px'};
    padding: ${props => props.padding || '10px'};
    padding-bottom: 50px;
    ${p =>
      !p.showActionButton &&
      `
      padding-bottom: 10px;
    `}
  }
`;

const AttachedDiv = styled.div`
  position: absolute;
  width: 100%;
  bottom: 20px;
  text-align: center;
`;

const StyledLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: #00bbb5;
`;

function Calendar({
  locale,
  selectRange,
  onChange,
  selectedValue,
  highlightWeek,
  selectPast,
  showActionButton,
  buttonOnClick,
  buttonLabel,
  minDate,
  maxDate,
  boxShadow,
  width,
  padding,
  ...restProps
}) {
  const [value, setValue] = useState(selectedValue || moment().toDate());
  const [week, setWeek] = useState(undefined);

  useEffect(() => {
    const range = moment(value).range('week');
    setWeek(range);
  }, [value]);

  const onValueChange = newValue => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Wrapper
      showActionButton={showActionButton}
      boxShadow={boxShadow}
      width={width}
      padding={padding}
    >
      <ReactCalendar
        minDate={!selectPast ? new Date() : minDate}
        maxDate={maxDate}
        locale={locale}
        onChange={onValueChange}
        selectRange={selectRange}
        value={value}
        tileClassName={({ date, view }) => {
          let tileClass = null;
          if (
            view === 'month' &&
            week &&
            week.contains(date) &&
            highlightWeek
          ) {
            tileClass = 'highlight__week';
            const thisDay = date.getDay();
            if (thisDay === 1)
              tileClass = `${tileClass} highlight__week__start__day`;
            if (thisDay === 0)
              tileClass = `${tileClass} highlight__week__end__day`;
          }
          return tileClass;
        }}
        {...restProps}
      />
      {showActionButton && (
        <AttachedDiv>
          <StyledLink onClick={buttonOnClick}>{buttonLabel}</StyledLink>
        </AttachedDiv>
      )}
    </Wrapper>
  );
}

Calendar.defaultProps = {
  buttonLabel: '指定の期間でダウンロード',
  locale: 'ja',
  selectedValue: null,
  selectRange: false,
  highlightWeek: true,
  selectPast: false,
  showActionButton: true,
  buttonOnClick: () => {},
  onChange: () => {},
  minDate: null,
  maxDate: null,
  boxShadow: undefined,
  width: undefined,
  padding: undefined,
};

Calendar.propTypes = {
  buttonLabel: PropTypes.string,
  locale: PropTypes.string,
  selectedValue: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  selectRange: PropTypes.bool,
  highlightWeek: PropTypes.bool,
  selectPast: PropTypes.bool,
  showActionButton: PropTypes.bool,
  buttonOnClick: PropTypes.func,
  onChange: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  boxShadow: PropTypes.string,
  width: PropTypes.string,
  padding: PropTypes.string,
};

export default Calendar;
