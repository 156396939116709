import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Label } from '../Atoms';
import {
  Loader,
  MemberListItem,
  SearchInput,
  SelectedMember,
} from '../Molecules';
import { Modal } from '../../index';

const ModalExtended = styled(Modal)`
  padding-bottom: 0;
  display: block;
`;

const CrossIcon = styled.div`
  position: absolute;
  display: block;
  right: 0px;
  top: 20px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #cdef;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 10px;
    right: 10px;
    font-size: 20px;
    background-color: #00bbb5;
    width: 25px;
    height: 2px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 10px;
    right: 10px;
    font-size: 20px;
    background-color: #00bbb5;
    width: 25px;
    height: 2px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
`;

const Header = styled.div`
  position: relative;
  padding: 15px;
  text-align: center;
`;

const HeaderTitle = styled.h3`
  font-weight: 100;
  font-size: 24px;
`;

const ModalBody = styled.div`
  padding: 40px 38px;
  background-color: #ffffff;
`;
const MemberSection = styled.div`
  margin-top: 20px;
`;

const SelectedMemberSection = styled.div`
  min-height: 200px;
`;

const SelectedHostLabel = styled.p`
  margin-bottom: 10px;
  color: #484e62;
  font-size: 14px;
  font-weight: 200;
`;
const MemberListWrapper = styled.div`
  min-height: 50vh;
`;
const MembersList = styled.div`
  margin-bottom: 20px;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
`;

const SearchResultSection = styled.div`
  display: block;
  z-index: 1;
  background-color: #ffffff;
  width: 100%;
  padding: 30px 0 0 0;
  position: relative;
`;

const SearchMemberWrapper = styled.div`
  position: relative;
  display: inline-grid;
  width: 100%;
  z-index: 2;
  overflow-y: scroll;
`;

const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`;

const SearchMember = ({
  members,
  employees,
  isFetching,
  onSelectEmployee,
  onRemoveSelectedEmployee,
  onTypingBlur,
  searchScreenButtonLabel,
}) => {
  if (isFetching) {
    return (
      <>
        <CenteredDiv>
          <Loader />
        </CenteredDiv>
        <Button onClick={onTypingBlur}>{searchScreenButtonLabel}</Button>
      </>
    );
  }
  return (
    <SearchResultSection>
      <MemberListWrapper>
        <SearchMemberWrapper>
          {members.map(item => {
            return (
              <MemberListItem
                key={item.uqid}
                id={item.uqid}
                iconUri={item?.icon_uri?.thumb?.url || item?.icon_uri?.url}
                label={item?.name}
                selected={employees.some(
                  employee => employee.uqid === item.uqid,
                )}
                authorizedSchedulers={item?.authorized_schedulers}
                onClick={() => onSelectEmployee(item)}
                onRemoveSelectedEmployee={() => onRemoveSelectedEmployee(item)}
              />
            );
          })}
        </SearchMemberWrapper>
      </MemberListWrapper>
      <Button onClick={onTypingBlur}>{searchScreenButtonLabel}</Button>
    </SearchResultSection>
  );
};

SearchMember.defaultProps = {
  isFetching: false,
  onSelectEmployee: () => {},
  onRemoveSelectedEmployee: () => {},
  onTypingBlur: () => {},
  searchScreenButtonLabel: null,
  members: [],
  employees: [],
};

SearchMember.propTypes = {
  isFetching: PropTypes.bool,
  onSelectEmployee: PropTypes.func,
  onRemoveSelectedEmployee: PropTypes.func,
  onTypingBlur: PropTypes.func,
  searchScreenButtonLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  members: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  employees: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};

function SelectHost(props) {
  const {
    employees,
    isOpen,
    toggleModal,
    onBackgroundClick,
    onEscapeKeydown,
    headerTitle,
    modalStyle,
    handleSearchChange,
    searchedEmployees,
    isFetching,
    onSelectEmployee,
    onRemoveSelectedEmployee,
    submitButtonLabel,
    handleSubmit,
  } = props;
  const [isSearching, setSearching] = useState(false);

  const onTypingFocus = () => {
    setSearching(true);
  };

  const onTypingBlur = () => {
    setSearching(!isSearching);
  };

  const isOnlyItem = employees.length === 1;
  const allSelectedMembersList =
    employees.length > 0 &&
    employees.map(item => {
      return (
        <SelectedMember
          key={item.uqid}
          id={item.uqid}
          src={item?.icon_uri?.thumb?.url || item?.icon_uri?.url}
          label={item?.name}
          showCrossIcon={!isOnlyItem}
          onClick={() => props.onRemoveSelectedEmployee(item)}
        />
      );
    });

  return (
    <ModalExtended
      isOpen={isOpen}
      toggleModal={toggleModal}
      onBackgroundClick={onBackgroundClick || toggleModal}
      onEscapeKeydown={onEscapeKeydown || toggleModal}
      width="550px"
      maxHeight="90vh"
      minHeight="80vh"
      {...modalStyle}
    >
      <Header>
        <HeaderTitle>{headerTitle}</HeaderTitle>
        <CrossIcon onClick={toggleModal} />
      </Header>

      <ModalBody>
        <SearchInput
          placeholder="Search by name"
          onChange={handleSearchChange}
          onFocus={onTypingFocus}
        />

        {isSearching && (
          <SearchMember
            members={searchedEmployees}
            employees={employees}
            isFetching={isFetching}
            onSelectEmployee={onSelectEmployee}
            onRemoveSelectedEmployee={onRemoveSelectedEmployee}
            onTypingBlur={onTypingBlur}
          />
        )}

        {!isSearching &&
          (employees.length <= 0 ? (
            <CenteredDiv>
              <Label>No data to display.</Label>
            </CenteredDiv>
          ) : (
            <MemberSection>
              <SelectedMemberSection>
                <SelectedHostLabel>Selected Hosts</SelectedHostLabel>
                <MemberListWrapper>
                  <MembersList>{allSelectedMembersList}</MembersList>
                </MemberListWrapper>
                <Button
                  onClick={() => {
                    toggleModal();
                    handleSubmit(employees);
                  }}
                >
                  {submitButtonLabel}
                </Button>
              </SelectedMemberSection>
            </MemberSection>
          ))}
      </ModalBody>
    </ModalExtended>
  );
}

SelectHost.defaultProps = {
  toggleModal: () => {},
  onBackgroundClick: () => {},
  onEscapeKeydown: () => {},
  headerTitle: 'Select Hosts',
  searchedEmployees: [],
  employees: [],
  onSelectEmployee: () => {},
  onRemoveSelectedEmployee: () => {},
  handleSubmit: () => {},
  submitButtonLabel: 'Fix the hosts in the above',
  searchScreenButtonLabel: 'Back to confirm screen',
  handleSearchChange: () => {},
  isFetching: false,
  modalStyle: undefined,
};

SelectHost.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func,
  onBackgroundClick: PropTypes.func,
  onEscapeKeydown: PropTypes.func,
  handleSearchChange: PropTypes.func,
  employees: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  searchedEmployees: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  handleSubmit: PropTypes.func,
  submitButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  searchScreenButtonLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSelectEmployee: PropTypes.func,
  onRemoveSelectedEmployee: PropTypes.func,
  modalStyle: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  isFetching: PropTypes.bool,
};

export default SelectHost;
