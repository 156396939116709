import styled from 'styled-components';
import { layout, borderRadius, margin } from 'styled-system';
import avatarDefault from '../../Icons/avatar-default.png';

export const Avatar = styled.img`
  ${layout}
  ${borderRadius}
  ${margin}
`;

Avatar.defaultProps = {
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  src: avatarDefault,
};
