import React from 'react';
import PropTypes from 'prop-types';

function CrossIcon({ style, size, fill, strokeWidth, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16.224 16.224"
      style={style}
      onClick={onClick}
    >
      <title id="title">Close</title>
      <g transform="translate(-44.815 -32.314)">
        <line
          x2="13.397"
          y2="12.504"
          transform="translate(46.228 34.175)"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
        <line
          x1="12.504"
          y2="13.397"
          transform="translate(46.674 33.728)"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
}

CrossIcon.defaultProps = {
  size: 18,
  strokeWidth: 2,
  fill: '#00bbb5',
  style: {},
  onClick: () => false,
};

CrossIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onClick: PropTypes.func,
};

export default CrossIcon;
