import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Input, Label } from '../Atoms';

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: ${props => props.width};
`;

const InputLabel = styled(Label)`
  margin: 0 16px;
`;

const InputDiv = styled.div`
  width: 100%;
`;

function InputWithInlineLabel({ id, label, width, ...restProps }) {
  return (
    <InputWrapper width={width}>
      <InputLabel primary htmlFor={id}>
        {label}
      </InputLabel>
      <InputDiv>
        <Input id={id} {...restProps} />
      </InputDiv>
    </InputWrapper>
  );
}

InputWithInlineLabel.defaultProps = {
  width: '100%',
  label: '#',
  id: '',
};

InputWithInlineLabel.propTypes = {
  width: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
};

export { InputWithInlineLabel };
