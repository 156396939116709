import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import gridSVG from '../../../Image/grid.svg';
import receptionLogo from '../../../Image/icon_reception.svg';
import schedulingLogo from '../../../Image/icon_scheduling.svg';
import roomsLogo from '../../../Image/icon_rooms.svg';
import otherProductsLogo from '../../../Image/other_products.svg';
import AppLink from './AppLink';

const activeGreen =
  'invert(8%) sepia(16%) saturate(123%) hue-rotate(101deg) brightness(104%) contrast(91%)';

const GridIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  margin-top: 8px;
  cursor: pointer;
  filter: ${props => (props.toggled ? activeGreen : '')};
  &:hover {
    filter: ${activeGreen};
  }
`;

const OwnedProduct = styled.div`
  margin-bottom: 8.5px;
  margin-top: 8px;
  padding-bottom: 16px;
  border-bottom: ${props => props.displayLine ? "1px solid #e7e9ea" : ''};
`;

const OtherProduct = styled.div`
  padding-bottom: 16px;
  margin-top: 8px;
`;

const DropDownSectionTitle = styled.span`
  margin-left: 22px;
  font-size: 11px;
  text-align: left;
  font-weight: ${props => props.fontWeight};
  line-height: ${props => props.lineHeight};
  color: ${props => props.color};
`;

const DropDownContent = styled.div`
  position: absolute;
  z-index: 10;
  border-radius: 10px;
  padding-top: ${props => props.pt};
  width: ${props => props.width};
  background-color: ${props => props.backgroundColor};
  box-shadow: 0 10px 26px 0 rgba(63, 78, 90, 0.16);
`;

function addIconsToProducts(owned, other) {
  const iconMap = {
    reception: receptionLogo,
    meetingroom: roomsLogo,
    scheduling: schedulingLogo,
    other: otherProductsLogo,
  };
  function addIconToProduct(obj) {
    const icon = iconMap[obj.product_type];
    return { ...obj, icon };
  }
  const ownedWithIcons = owned.map(addIconToProduct);
  const otherWithIcons = other.map(addIconToProduct);
  return { updatedOwned: ownedWithIcons, updatedOther: otherWithIcons };
}

function ProductDropDown({
  owned,
  other,
  ownedLabel,
  otherLabel,
  onClick,
  currentApp,
  color,
  lineHeight,
  fontWeight,
  width,
  pt,
  backgroundColor,
}) {
  const [toggled, setToggled] = useState(false);
  const productDropDownRef = useRef(null);
  const { updatedOwned, updatedOther } = addIconsToProducts(owned, other);

  const handleClickOutside = event => {
    if (
      productDropDownRef.current &&
      !productDropDownRef.current.contains(event.target)
    ) {
      setToggled(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={productDropDownRef}>
      <GridIcon
        src={gridSVG}
        onClick={() => setToggled(!toggled)}
        toggled={toggled}
      />
      {toggled && (
        <DropDownContent width={width} pt={pt} backgroundColor={backgroundColor}>
        {updatedOwned.length > 0 && (
          <>
          <DropDownSectionTitle color={color} lineHeight={lineHeight} fontWeight={fontWeight}>
            {ownedLabel}
          </DropDownSectionTitle>
          <OwnedProduct displayLine={updatedOwned.length > 0 && updatedOther.length > 0}>
            {updatedOwned.map(({ product_type, icon, name, onClick, description }) => {
              if (product_type !== currentApp) {
                return (
                  <AppLink
                    key={'appLink-' + product_type}
                    product={product_type}
                    icon={icon}
                    productName={name}
                    onClick={() => onClick()}
                    isActive
                    description={description}
                    currentApp={currentApp}
                  />
                );
              }
              return null;
            })}
          </OwnedProduct>
          </>)}
          {updatedOther.length > 0 && (
            <>
          <DropDownSectionTitle color={color} lineHeight={lineHeight} fontWeight={fontWeight}>
            {otherLabel}
          </DropDownSectionTitle>
          <OtherProduct>
            {updatedOther.map(({ product_type, icon, link, name, description }) => (
              <AppLink
                key={'appLink-' + product_type}
                link={link}
                icon={icon}
                product={product_type}
                productName={name}
                isActive={false}
                description={description}
              />
            ))}
          </OtherProduct>
          </> )}
        </DropDownContent>
      )}
    </div>
  );
}

ProductDropDown.defaultProps = {
  owned: null,
  other: null,
  ownedLabel: null,
  otherLabel: null,
  currentApp: null,
  color: '#68878d',
  lineHeight: null,
  fontWeight: "500",
  width: '347px',
  pt: '16px',
  backgroundColor: '#fff'
};

ProductDropDown.propTypes = {
  owned: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  other: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  ownedLabel: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  otherLabel: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  currentApp: PropTypes.string,
  color: PropTypes.string,
  lineHeight: PropTypes.string,
  fontWeight: PropTypes.string,
  width: PropTypes.string,
  pt: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default ProductDropDown;
