import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Input, Label } from '../Atoms';
import { ErrorUI } from './ErrorUI';
import { theme as Rtheme } from '../Utils/theme';

const InputWrapper = styled.div`
  width: ${props => props.width};
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 5px;
  margin-bottom: 10px;
  align-items: flex-end;
`;

const LabelRight = styled(Label)`
  font-size: 13px;
  text-align: right;
  color: ${Rtheme.colors.borderColorFocus};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const InputDiv = styled.div`
  position: relative;
  width: 100%;
`;

function InputWithLabel({
  id,
  labelLeft,
  labelRight,
  rightLabelOnClick,
  meta,
  width,
  errorWhiteSpace,
  ...restProps
}) {
  return (
    <InputWrapper width={width}>
      <LabelWrapper>
        <Label htmlFor={id}>{labelLeft}</Label>
        <LabelRight onClick={rightLabelOnClick}>{labelRight}</LabelRight>
      </LabelWrapper>
      <InputDiv>
        <Input id={id} {...restProps} />
        <ErrorUI meta={meta} whiteSpace={errorWhiteSpace}/>
      </InputDiv>
    </InputWrapper>
  );
}

InputWithLabel.defaultProps = {
  id: undefined,
  labelLeft: '',
  labelRight: '',
  width: '100%',
  meta: {
    touched: false,
    error: undefined,
    warning: undefined,
  },
  rightLabelOnClick: () => {},
};

InputWithLabel.propTypes = {
  id: PropTypes.string,
  labelLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelRight: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  rightLabelOnClick: PropTypes.func,
};

export { InputWithLabel };
