import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonTabIcon } from '../Atoms/ButtonTabIcon';

const ButtonTabNav = styled.div``;

const ButtonTabIconWrapper = styled.div`
  margin-right: ${props => (props.last ? '0' : '8px')};
  display: inline-block;
`;

const ButtonTabContent = styled.div``;

export function ButtonTab({ children }) {
  return <div>{children}</div>;
}

ButtonTab.defaultProps = {
  children: undefined,
};

ButtonTab.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
};

export function ButtonTabs(props) {
  const { children } = props;
  const [active, setActive] = useState(0);

  const header = React.Children.map(children, (child, key) => {
    if (!React.isValidElement(child)) return;

    const { name } = child.props;
    // eslint-disable-next-line consistent-return
    return (
      <ButtonTabIconWrapper last={key === children.length - 1}>
        <ButtonTabIcon
          label={name}
          index={key}
          handlerClickEvent={setActive}
          active={key === active}
          width={props.tabWidth}
        />
      </ButtonTabIconWrapper>
    );
  });

  const content = React.Children.map(children, (child, key) => {
    if (key === active) {
      return child;
    }
    return null;
  });

  return (
    <div>
      <ButtonTabNav className="tab-header">{header}</ButtonTabNav>
      <ButtonTabContent className="tab-content">{content}</ButtonTabContent>
    </div>
  );
}

ButtonTabs.defaultProps = {
  tabWidth: null,
  children: undefined,
};

ButtonTabs.propTypes = {
  tabWidth: PropTypes.string,
  children(props, propName, componentName) {
    const prop = props[propName];
    let error = null;
    React.Children.forEach(prop, function validateChildrenPropType(child) {
      if (child && child.type !== ButtonTab) {
        error = new Error(
          `\`${componentName}\` children should be of type \`ButtonTab\`.`,
        );
      }
    });
    return error;
  },
};
