import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { slice } from 'lodash';

const moment = extendMoment(Moment);

moment.tz.setDefault('Japan');

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

function getThisWeek(date) {
  const range = date.range('week');

  const week = Array.from(range.by('day'));

  return { week: slice(week, 0, 5), selectedDate: moment(date), range };
}

function getNextWeek(date) {
  date.add(1, 'week');

  const range = date.range('week');

  const week = Array.from(range.by('day'));

  return { week: slice(week, 0, 5), selectedDate: date };
}

function getPreviousWeek(date) {
  date.subtract(1, 'week');

  const range = date.range('week');

  const week = Array.from(range.by('day'));

  return { week: slice(week, 0, 5), selectedDate: date };
}

function getHoursOfTheDay(businessOpeningTime, businessClosingTime, duration) {
  let time = moment(businessOpeningTime, 'hh:mm');
  const data = [];
  let hour = time.hours();
  let minute = time.minutes();
  minute = minute < 10 ? `0${minute}` : minute;
  let fullTime = `${hour}:${minute}`;

  while (time.diff(moment(businessClosingTime, 'hh:mm')) < 0) {
    data.push(fullTime);
    time = time.add(duration, 'm');
    hour = time.hours();
    minute = time.minutes();
    minute = minute < 10 ? `0${minute}` : minute;
    fullTime = `${hour}:${minute}`;
  }
  return data;
}

function isDateEarlier(date1, date2) {
  const difference = date1.startOf('day').diff(date2.startOf('day'));
  if (difference < 0) {
    return 'earlier';
  }
  return difference > 0 ? 'later' : 'equal';
}

function isTimeEarlier(time1, time2) {
  const difference = time1.diff(time2);
  if (difference < 0) {
    return 'earlier';
  }
  return difference > 0 ? 'later' : 'equal';
}

function getJapaneseDay(day) {
  switch (day) {
    case 0:
      return '日';
    case 1:
      return '月';
    case 2:
      return '火';
    case 3:
      return '水';
    case 4:
      return '木';
    case 5:
      return '金';
    case 6:
      return '土';
    default:
      return '';
  }
}

function calculateAvailableTime(timeSlots) {
  const m = timeSlots.reduce((obj, item) => {
    if (item && item.length) {
      const date = moment(item[0].startTime).format('MM-DD-yyyy');
      const slots = item.map(({ startTime }) => {
        if (isTimeEarlier(moment(startTime), moment()) === 'later') {
          return moment(startTime).format('HH:mm');
        }
        return null;
      });
      obj[date] = [...slots];
      return obj;
    }
    return obj;
  }, {});

  return m;
}

const getCalendarOptions = options => {
  return {
    today: moment(),
    currentDate: moment(),
    selectedDate: moment(),
    calculateAvailableTime,
    duration: options.duration,
    currentWeek: getThisWeek(options.currentDate),
    businessOpeningTime: options.businessOpeningTime,
    businessClosingTime: options.businessClosingTime,
    getThisWeek,
    getNextWeek,
    getPreviousWeek,
    getJapaneseDay,
    isTimeEarlier,
    isDateEarlier,
    getHoursOfTheDay: getHoursOfTheDay(
      options.businessOpeningTime,
      options.businessClosingTime,
      options.duration,
    ),
  };
};

export {
  getThisWeek,
  getPreviousWeek,
  getNextWeek,
  getHoursOfTheDay,
  isDateEarlier,
  isTimeEarlier,
  getJapaneseDay,
  moment,
  getCalendarOptions,
};
