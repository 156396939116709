import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { layout, margin } from 'styled-system';
import ReactTooltip from 'react-tooltip';

import { InputSelect } from './InputSelect';
import { Button } from '../Atoms';
import { ErrorUI } from './ErrorUI';
import { theme as Rtheme } from '../Utils/theme';
import { DefaultSelect } from './DefaultSelect';

const CustomReactTooltip = styled(ReactTooltip).attrs({
  className: 'custom-tooltip',
})`
  &.custom-tooltip {
    color: ${Rtheme.colors.white};
    border-radius: 6px;
    width: 85px;
    height: 25px;
    box-shadow: 0 2px 10px 0 ${Rtheme.colors.boxShadow};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;

    transition: visibility 0s 0.375s, opacity 0.375s ease-in-out !important;

    &.show {
      transition: visibility 0s 0s, opacity 0.375s ease-in-out !important;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  ${layout}
  ${margin}
  min-width: 207px;
`;

function InputSelectWithButton({
  buttonBorderRadius,
  children,
  inputBorderRadius,
  mr,
  width,
  meta,
  showCopiedTooltip,
  tooltipText,
  tooltipBackgroundColor,
  onClickButton,
  buttonLabel,
  disabled,
  removeRightBorder,
  isMobileView,
  ...restProps
}) {
  return (
    <>
      <Wrapper width={width} mr={mr} height={restProps.height}>
        <div style={{ flex: '1 1 auto' }}>
          {isMobileView ? (
            <DefaultSelect
              {...restProps}
              isDisabled={disabled}
              borderRadius={inputBorderRadius}
              removeRightBorder
              borderOnFocus={false}
              width={restProps.inputSelectWidth}
              input={{ value: restProps.value, onChange: restProps.onChange }}
            />
          ) : (
            <InputSelect
              {...restProps}
              isDisabled={disabled}
              borderRadius={inputBorderRadius}
              removeRightBorder
              borderOnFocus={false}
              width={restProps.inputSelectWidth}
            />
          )}
        </div>
        <div style={{ flex: '0 1 auto' }}>
          <Button
            onClick={onClickButton}
            data-for="tooltip"
            data-tip={tooltipText}
            data-event="click"
            data-event-off="click"
            disabled={disabled}
            {...restProps}
            outlined
            borderRadius={removeRightBorder ? '0 0 0 0' : buttonBorderRadius}
            width={restProps.buttonWidth}
            minWidth={restProps.buttonMinWidth}
            removeRightBorder={removeRightBorder}
          >
            {buttonLabel}
          </Button>
        </div>
      </Wrapper>
      <ErrorUI meta={meta} />
      {showCopiedTooltip && (
        <CustomReactTooltip
          isCapture
          id="tooltip"
          delayHide={1000}
          place="bottom"
          effect="solid"
          backgroundColor={tooltipBackgroundColor}
        />
      )}
    </>
  );
}

InputSelectWithButton.defaultProps = {
  width: '100%',
  buttonBorderRadius: '0px 5px 5px 0px',
  buttonWidth: '100%',
  buttonMinWidth: '108px',
  inputSelectWidth: '100%',
  inputBorderRadius: '5px 0px 0px 5px',
  buttonLabel: 'Click Me!',
  showShadowOnFocus: false,
  meta: { error: undefined, touched: undefined },
  showCopiedTooltip: false,
  tooltipText: 'コピーしました',
  tooltipBackgroundColor: '#6e52ff',
  removeRightBorder: false,
  children: undefined,
  onClickButton: () => {},
  mr: undefined,
  disabled: false,
};

InputSelectWithButton.propTypes = {
  width: PropTypes.string,
  buttonBorderRadius: PropTypes.string,
  buttonWidth: PropTypes.string,
  buttonMinWidth: PropTypes.string,
  inputSelectWidth: PropTypes.string,
  inputBorderRadius: PropTypes.string,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showShadowOnFocus: PropTypes.bool,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  showCopiedTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
  tooltipBackgroundColor: PropTypes.string,
  removeRightBorder: PropTypes.bool,
  onClickButton: PropTypes.func,
  mr: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
};

export { InputSelectWithButton };
