import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Label = styled.span`
  height: 20px;
  padding: 2px 4px 3px;
  border-radius: 3px;
  background-color: ${({ bgColor }) => bgColor};
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.2;
  letter-spacing: normal;
  color: ${({ fontColor }) => fontColor};
  cursor: pointer;
`;

function Tag({ bgColor, fontColor, children }) {
  return (
    <Label bgColor={bgColor} fontColor={fontColor}>
      {children}
    </Label>
  );
}

Tag.defaultProps = {
  bgColor: '#57c2e91a',
  fontColor: '#57c2e9',
  children: '',
};

Tag.propTypes = {
  bgColor: PropTypes.string,
  fontColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Tag;
