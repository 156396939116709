import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FadeIn } from 'animate-css-styled-components';
import avatarDefault from '../../../Icons/avatar-default.png';

import { Avatar, Col, Hr, Label, Row } from '../../../index';
import { theme as Rtheme } from '../../Utils/theme';

const IconSVG = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 25px;
`;

const DropDownWrapper = styled.div`
  position: absolute;
  display: block;
  top: ${p => p.top};
  right: ${p => p.right};
  padding: 20px 20px 0;
  width: 210px;
  height: auto;
  background: #ffffff;
  box-shadow: 0 20px 40px 10px ${Rtheme.colors.shadow};
  border-radius: 3px;
  overflow: hidden;
`;

function NavDropDown({
  accountList,
  companyList,
  currentUser,
  dropdownRight,
  dropdownTop,
  showHr,
  showCompanyInfo,
}) {
  return (
    <FadeIn duration="0.2s">
      <DropDownWrapper top={dropdownTop} right={dropdownRight}>
        <Row style={{ flexFlow: 'row nowrap' }}>
          <Col>
            <Avatar
              height="40px"
              width="40px"
              src={currentUser.icon_uri.url || avatarDefault}
            />
          </Col>
          <Col>
            <Row ml="10px" mt="7px">
              <Label isCursor fontSize="16px">
                {currentUser.name}
              </Label>
            </Row>
            <Row ml="10px">
              <Label isCursor fontSize="11px" color="#8ca0b3">
                {currentUser.email}
              </Label>
            </Row>
          </Col>
        </Row>

        <div
          style={{
            marginLeft: '7px',
            marginTop: '-23px',
            marginBottom: '30px',
          }}
        >
          {accountList.map(item => (
            <Row
              key={`account-${
                item.id || item.label || item.icon || ''
              }`.toLowerCase()}
              mt="18px"
              isCursor
              onClick={item.onClick}
            >
              <Col display="flex" alignItems="center">
                <IconSVG src={item.icon} />
                <Label isCursor fontSize="16px">
                  {item.label}
                </Label>
              </Col>
            </Row>
          ))}

          {showHr && (
            <Hr mt="18px" mb="5px" color="#e5e5e5" height="1px" border="none" />
          )}

          {showCompanyInfo && (
            <Row mt="18px" mb="5px" isCursor>
              <Col display="flex" alignItems="center">
                <Label isCursor fontSize="13px">
                  ディライテッド株式会社
                </Label>
              </Col>
            </Row>
          )}

          {companyList.map(item => (
            <Row
              key={`company-${item.label || ''}`}
              mt="18px"
              isCursor
              onClick={item.onClick}
            >
              <Col display="flex" alignItems="center">
                <IconSVG src={item.icon} />
                <Label isCursor fontSize="16px">
                  {item.label}
                </Label>
              </Col>
            </Row>
          ))}
        </div>
      </DropDownWrapper>
    </FadeIn>
  );
}

NavDropDown.defaultProps = {
  accountList: [],
  companyList: [],
  currentUser: {},
  dropdownRight: null,
  dropdownTop: null,
  showHr: false,
  showCompanyInfo: false,
};

NavDropDown.propTypes = {
  accountList: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  companyList: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  currentUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  dropdownRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // eslint-disable-line react/forbid-prop-types
  dropdownTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // eslint-disable-line react/forbid-prop-types,
  showHr: PropTypes.bool,
  showCompanyInfo: PropTypes.bool,
};

export default NavDropDown;
