import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { height, borderRadius } from 'styled-system';
import { ErrorUI } from '../Molecules/ErrorUI';

const TextArea = styled.textarea`
  box-sizing: border-box;
  margin-bottom: 5px;
  ${height};
  width: ${p => (p.width ? p.width : '100%')};
  ${borderRadius};
  border: solid 1px #d2e9e7;
  padding: 12px 18px;
  font-size: 15px;
  resize: none;
  color: #314143;
  background: #ffffff !important;
  transition: all 0.4s ease, border-color 0.15s ease-in-out;

  &:focus {
    color: #314143;
    outline: none !important;
    border: 1px solid #00bbb5;
  }
`;

export function TextAreaInput({ id, meta, ...restProps }) {
  return (
    <div>
      <TextArea id={id} {...restProps.input} {...restProps} />
      <ErrorUI meta={meta} />
    </div>
  );
}

TextAreaInput.defaultProps = {
  id: undefined,
  height: '100px',
  width: '100%',
  placeholder: 'Descriptions...',
  borderRadius: '5.4px',
  meta: { error: undefined, touched: undefined },
};

TextAreaInput.propTypes = {
  id: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  borderRadius: PropTypes.string,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
