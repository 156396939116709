import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Label, TextArea } from '../Atoms';
import { ErrorUI } from './ErrorUI';

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: ${props => props.width};
`;

const InputLabel = styled(Label)`
  @media (min-width: 576px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
`;

const InputDiv = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: 576px) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
`;

function TextAreaWithInlineLabel({ id, label, meta, width, ...restProps }) {
  return (
    <InputWrapper width={width}>
      <InputLabel primary htmlFor={id}>
        {label}
      </InputLabel>
      <InputDiv>
        <TextArea id={id} {...restProps} />
        <ErrorUI meta={meta} />
      </InputDiv>
    </InputWrapper>
  );
}

TextAreaWithInlineLabel.defaultProps = {
  id: null,
  label: null,
  width: '100%',
  meta: {
    touched: false,
    error: undefined,
    warning: undefined,
  },
};

TextAreaWithInlineLabel.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  width: PropTypes.string,
};

export { TextAreaWithInlineLabel };
