import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from '../../Utils/Grid';
import { Heading, IconButton } from '../../Atoms';
import { moment } from './momentUtils';

export function CalendarHeader({
  options,
  getPrevWeek,
  getNextWeek,
  isTimeSlotLoading,
  timeSlotsHasError,
  children,
}) {
  const {
    currentWeek: { week, selectedDate },
  } = options;

  return (
    <>
      {!timeSlotsHasError && (
        <Row mt={['10px', '15px']} pl={[null, '5px']}>
          <Col col>
            <Row
              width={['100%', '250px']}
              justifyContent="space-between"
              mb="10px"
            >
              <Col col sm={8} pr="0px">
                <Heading size={6}>{selectedDate.year()}年</Heading>
                <Heading size={2}>
                  {week[0].format('M/D')} -{' '}
                  {week[week.length - 1].format('M/D')}
                </Heading>
              </Col>
              <Col col sm={4} display="flex" justifyContent="flex-end" px="0px">
                <IconButton
                  mr="3px"
                  disabled={
                    isTimeSlotLoading || moment(week[0]).isBefore(moment())
                  }
                  onClick={getPrevWeek}
                >
                  <i className="fa fa-angle-left" aria-hidden="true" />
                </IconButton>
                <IconButton disabled={isTimeSlotLoading} onClick={getNextWeek}>
                  <i className="fa fa-angle-right" aria-hidden="true" />
                </IconButton>
              </Col>
            </Row>
            <Row>
              <Col mb={['5px', '12px']}>
                <Heading size={3}>ご希望の日時を選択してください</Heading>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs padding="0px">
          {children}
        </Col>
      </Row>
    </>
  );
}

CalendarHeader.defaultProps = {
  options: {},
  isTimeSlotLoading: false,
  timeSlotsHasError: false,
  children: null,
};

CalendarHeader.propTypes = {
  options: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isTimeSlotLoading: PropTypes.bool,
  timeSlotsHasError: PropTypes.bool,
  getPrevWeek: PropTypes.func.isRequired,
  getNextWeek: PropTypes.func.isRequired,
  children: PropTypes.node,
};
