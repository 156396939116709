import React from 'react';
import styled from 'styled-components';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { theme as Rtheme } from '../Utils/theme';

const CKEditorWrapper = styled.div`
  --ck-border-radius: 5px;
  --ck-color-toolbar-border: ${Rtheme.colors.borderColor};
  --ck-color-base-border: ${Rtheme.colors.borderColor};

  --ck-color-base-action: ${Rtheme.colors.fontColorError};
  --ck-color-base-focus: ${Rtheme.colors.fontColorError};
  --ck-color-base-text: ${Rtheme.colors.fontColorError};
  --ck-color-base-active: ${Rtheme.colors.fontColorError};
  --ck-color-base-active-focus: ${Rtheme.colors.fontColorError};
  --ck-color-base-error: ${Rtheme.colors.fontColorError};

  --ck-color-button-default-hover-background: ${Rtheme.colors.borderColor};
  --ck-color-button-default-active-background: ${Rtheme.colors.borderColor};
  --ck-color-button-default-active-shadow: ${Rtheme.colors.borderColor};

  --ck-color-button-on-background: ${Rtheme.colors.borderColor};
  --ck-color-button-on-hover-background: ${Rtheme.colors.borderColor};
  --ck-color-button-on-active-background: ${Rtheme.colors.borderColor};
  --ck-color-button-on-active-shadow: ${Rtheme.colors.borderColor};

  --ck-color-tooltip-background: ${Rtheme.colors.fontColor};

  .ck-editor__editable_inline {
    min-height: 180px;
  }
`;

// TODO: Finish this component if we are using it or remove it if we are not
export function Editor() {
  return (
    <CKEditorWrapper>
      <CKEditor
        editor={ClassicEditor}
        config={{
          toolbar: [
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'imageUpload',
          ],
        }}
      />
    </CKEditorWrapper>
  );
}
