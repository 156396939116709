import React from 'react';
import styled from 'styled-components';
import { theme as Rtheme } from '../Utils/theme';

const BreadCrumbUl = styled.ul`
  padding: 10px 40px;
  list-style: none;
`;

const BreadcrumbItem = styled.li`
  display: inline;

  font-size: 13px;
  color: #314143;

  & + & {
    &:before {
      font-family: FontAwesome;
      padding: 18px;
      color: ${Rtheme.colors.fontColorSecondary};
      font-size: 18px;
      content: '\f105';
    }
  }
`;

const BreadCrumbItemLink = styled.a`
  text-decoration: none;
  font-size: 13px;
  color: ${Rtheme.colors.fontColorSecondary};
`;

// TODO: This component should have the text coming from props
const BreadCrumbs = () => {
  return (
    <BreadCrumbUl>
      <BreadcrumbItem>
        <BreadCrumbItemLink href="#">ダッシュボード</BreadCrumbItemLink>
      </BreadcrumbItem>
      <BreadcrumbItem>一般</BreadcrumbItem>
    </BreadCrumbUl>
  );
};

BreadCrumbs.defaultProps = {};

export { BreadCrumbs };
