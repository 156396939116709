import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Input, IconNew } from '../Atoms';

const InputWrapper = styled.div`
  position: relative;
  display: block;
  height: 40px;
  width: ${props => props.width};
`;

const InputIcon = styled.div`
  position: absolute;
  top: 12px;
  left: 10px;
`;

function SearchInput({
  id,
  icon,
  placeholder,
  meta,
  width,
  inputHeight,
  iconSize,
  iconColor,
  ...restProps
}) {
  return (
    <InputWrapper width={width}>
      <Input
        id={id}
        placeholder={placeholder}
        height={inputHeight}
        style={{ paddingLeft: '30px' }}
        {...restProps}
      />
      <InputIcon>
        <IconNew className={icon} fontSize={iconSize} color={iconColor} />
      </InputIcon>
    </InputWrapper>
  );
}

SearchInput.defaultProps = {
  id: undefined,
  width: '100%',
  icon: 'fa fa-search',
  iconSize: '16px',
  iconColor: 'grey',
  inputHeight: '40px',
  placeholder: 'Placeholder',
  meta: {
    touched: false,
    error: undefined,
    warning: undefined,
  },
};

SearchInput.propTypes = {
  id: PropTypes.string,
  width: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  inputHeight: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export { SearchInput };
