import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Booking from '../bigCalendar/Booking';
import navigationLogo from '../../../Icons/Icons-Navigation-Logo.svg';

const Wrapper = styled.div`
  border: solid 1px #d2e9e7;
  border-radius: 5px;
  padding-right: 30px;
`;

const Nav = styled.div`
  min-height: 104px;
  background: white;
  align-items: center !important;
  display: flex !important;
  margin-right: -30px;
  border-radius: 5px 5px 0 0;
`;

const HeaderDiv = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 40px;
  width: 100%;
`;

const Header = styled.p`
  font-size: 21px;
  font-weight: bold;
  line-height: 1.48;
  letter-spacing: 1.05px;
  color: rgb(49, 65, 67);
`;

const SubHeader = styled.p`
  font-size: 12px;
  font-weight: 200;
  line-height: 1.46;
  color: rgb(104, 135, 141);
  margin-left: 15px;
  white-space: pre-wrap;
`;

const BookingDiv = styled.div`
  padding-left: 40px;
`;

const Footer = styled.div`
  text-align: center;
  font-size: 10px;
  color: rgb(49, 65, 67);
  opacity: 0.7;
  margin-top: 15px;
  margin-bottom: 20px;
`;

const FooterImage = styled.img`
  margin-right: 11px;
  height: 15px;
  width: 15px;
  opacity: 0.6;
`;

export default function Preview({ title, description, ...restProps }) {
  return (
    <Wrapper>
      <Nav>
        <HeaderDiv>
          <Header>{title}</Header>
          <SubHeader>{description}</SubHeader>
        </HeaderDiv>
      </Nav>
      <BookingDiv>
        <Booking isPreview {...restProps} />
      </BookingDiv>
      <Footer>
        <FooterImage src={navigationLogo} />
        Powered by RECEPTIONIST Inc.
      </Footer>
    </Wrapper>
  );
}

Preview.defaultProps = {
  title: 'Receptionist',
  description: 'description of Receptionist',
};

Preview.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
