import React from 'react';
import PropTypes from 'prop-types';
import { ErrorUI } from './ErrorUI';
import { Label } from '../Atoms';
import { InputWithLeftAddon } from './index';

function InputWithLeftAddonAndLabel({ id, label, meta, width, ...restProps }) {
  return (
    <>
      <Label htmlFor={id}>{label}</Label>
      <InputWithLeftAddon id={id} {...restProps} />
      <ErrorUI meta={meta} />
    </>
  );
}

InputWithLeftAddonAndLabel.defaultProps = {
  id: undefined,
  label: undefined,
  width: '100%',
  meta: {
    touched: false,
    error: undefined,
    warning: undefined,
  },
};

InputWithLeftAddonAndLabel.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export { InputWithLeftAddonAndLabel };
