import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme as Rtheme } from '../Utils/theme';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

const SwitchLabel = styled.label`
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid ${Rtheme.colors.border};
  border-radius: 20px;
  margin: 0;
`;

const SwitchInner = styled.span`
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;

  &:disabled {
    background-color: ${Rtheme.colors.bgAlternative};
    cursor: not-allowed;

    &:before {
      background-color: ${Rtheme.colors.bgAlternative};
      cursor: not-allowed;
    }
  }

  &:before,
  &:after {
    display: block;
    float: left;
    width: 50%;
    height: ${props => props.height}px;
    padding: 0;
    line-height: ${props => props.height}px;
    color: ${Rtheme.colors.white};
    box-sizing: border-box;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
  }
  &:before {
    content: attr(data-yes);
    text-transform: uppercase;
    padding-left: 10px;
    background-color: ${props => props.enabledColor};
    color: ${Rtheme.colors.white};
  }
  &:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: ${props => props.disabledColor};
    color: ${Rtheme.colors.white};
    text-align: right;
  }
`;

const SwitchSwitch = styled.span`
  display: block;
  width: ${props => props.height - 10}px;
  margin: 5px;
  background: ${Rtheme.colors.white};
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${props => props.width - props.height}px;
  border: 0 solid ${Rtheme.colors.border};
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
`;

const SwitchWrapper = styled.div`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  ${props =>
    props.disabled
      ? `
    filter: grayscale(0.6) opacity(0.4);
    pointer-events: none;
  `
      : ''}

  ${HiddenCheckbox}:checked + ${SwitchLabel} {
    ${SwitchInner} {
      margin-left: 0;
    }
    ${SwitchSwitch} {
      right: 0;
    }
  }
`;

const SwitchCheckbox = ({
  id,
  width,
  name,
  height,
  labels,
  colors,
  disabled,
  ...props
}) => {
  return (
    <>
      <SwitchWrapper width={width} height={height} disabled={disabled}>
        <HiddenCheckbox name={name} id={id} {...props.input} {...props} />
        <SwitchLabel htmlFor={id}>
          <SwitchInner
            height={height}
            data-yes={labels[0]}
            data-no={labels[1]}
            enabledColor={colors[0]}
            disabledColor={colors[1]}
          />
          <SwitchSwitch width={width} height={height} />
        </SwitchLabel>
      </SwitchWrapper>
    </>
  );
};

SwitchCheckbox.defaultProps = {
  width: 78,
  height: 24,
  id: 'switchCheckbox-id',
  name: 'switchCheckbox-name',
  labels: ['Online', 'Offline'],
  colors: ['#00bbb5', '#e84c3d'],
  input: {
    value: false,
  },
  disabled: false,
};

SwitchCheckbox.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  id: PropTypes.string,
  name: PropTypes.string,
  labels: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  colors: PropTypes.arrayOf(PropTypes.string),
  input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  disabled: PropTypes.bool,
};

export { SwitchCheckbox };
