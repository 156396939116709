import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'styled-react-modal';
import { color, layout, flexbox, space } from 'styled-system';
import { theme as Rtheme } from '../Utils/theme';

const StyledModal = Modal.styled`
  border-radius: 6px;
  ${layout}
  ${color}
  ${flexbox}
  ${space}
`;

export function BaseModal({ children, ...props }) {
  return <StyledModal {...props}>{children}</StyledModal>;
}

BaseModal.defaultProps = {
  width: '20rem',
  height: '20rem',
  display: 'flex',
  isOpen: false,
  bg: Rtheme.colors.white,
  onBackgroundClick: () => {},
  onEscapeKeydown: () => {},
  toggleModal: () => {},
  children: undefined,
};

BaseModal.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  display: PropTypes.string,
  onBackgroundClick: PropTypes.func,
  onEscapeKeydown: PropTypes.func,
  bg: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
};
