import styled from 'styled-components';
import {
  Grid as BaseGrid,
  Row as BaseRow,
  Col as BaseCol,
} from 'react-styled-flexboxgrid';
import {
  borders,
  color,
  display,
  flexbox,
  layout,
  margin,
  padding,
  shadow,
} from 'styled-system';

export const Row = styled(BaseRow)`
  ${borders}
  ${color}
  ${display}
  ${flexbox}
  ${layout}
  ${margin}
  ${padding}
  ${shadow}
  cursor: ${p => p.isCursor && 'pointer'};
`;

export const Col = styled(BaseCol)`
  ${color}
  ${display}
  ${flexbox}
  ${layout}
  ${margin}
  ${padding}
  ${shadow}
  cursor: ${p => p.isCursor && 'pointer'};
`;

export const Grid = styled(BaseGrid)`
  ${margin}
  ${padding}
`;

export const Hr = styled.hr`
  ${borders}
  ${color}
  ${margin}
  ${layout}
`;
