import styled from 'styled-components';
import {
  color,
  display,
  fontFamily,
  fontSize,
  fontWeight,
  margin,
  position,
} from 'styled-system';
import { theme as Rtheme } from '../Utils/theme';

const Icon = styled.span`
  ${color}
  ${display}
  ${fontFamily}
  ${fontSize}
  ${fontWeight}
  ${margin}
  ${position}
  cursor: ${props => props.isCursor && 'pointer'};
`;

Icon.defaultProps = {
  fontSize: '25px',
  color: Rtheme.colors.primary,
};

export default Icon;
