import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { display } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Label from './Label';
import { ErrorUI } from '../Molecules/ErrorUI';

const Wrapper = styled.div`
  margin-bottom: 8px;
`;

const RadioLabel = styled(Label)`
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  margin-right: 15px;
  font-size: 15px;
  ${display}
`;

const RadioButtonIcon = styled.span`
  cursor: pointer;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: solid 2px ${themeGet('colors.shadow')};
  border-radius: 50%;
  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
`;

const HiddenRadio = styled.input`
  position: absolute;
  left: 0;
  opacity: 0;
  cursor: pointer;
  &:checked + ${RadioButtonIcon} {
    background-color: ${themeGet('colors.primary')};
  }
  &:checked + ${RadioButtonIcon}:after {
    display: block;
  }
`;

export function RadioButton({
  children,
  name,
  trueFor,
  meta,
  labelColor,
  display,
  ...restProps
}) {
  const { onChange, value } = restProps.input;
  function handleChange() {
    onChange(trueFor);
  }
  return (
    <>
      <Wrapper>
        <RadioLabel
          color={labelColor}
          display={display}
          onClick={() => handleChange()}
        >
          {children}
          <HiddenRadio
            type="radio"
            checked={value === trueFor}
            name={name}
            {...restProps}
          />
          <RadioButtonIcon />
        </RadioLabel>
      </Wrapper>
      <ErrorUI meta={meta} />
    </>
  );
}

RadioButton.defaultProps = {
  trueFor: 30,
  onChange: () => {},
  meta: { error: undefined, touched: undefined },
  input: {
    onChange: () => {},
    value: '',
  },
  name: undefined,
  labelColor: '#314143',
  children: undefined,
  display: 'initial',
};

RadioButton.propTypes = {
  trueFor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  labelColor: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  display: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
};
