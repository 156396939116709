import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { margin } from 'styled-system';
import { Input, IconNew } from '../Atoms';
import { theme as Rtheme } from '../Utils/theme';

const touch = keyframes`
  0% {
    transform: scale3d(0,1,1);
  }
  100% {
    transform: scale3d(1,1,1);
  }
`;

const TabElementWrapper = styled.div`
  position: relative;
  min-width: 60px;
  text-align: center;
  margin: 0 80px 16px 0;
  color: ${Rtheme.colors.fontColorSecondary};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.1s linear;
  &.active {
    color: ${Rtheme.colors.primary};
    &:before {
      content: ' ';
      position: absolute;
      animation: ${touch} 0.1s linear forwards;
      width: 100%;
      height: 3px;
      bottom: -10px;
      right: 0px;
      background-color: ${Rtheme.colors.primary};
    }
  }
  ${margin};
`;

export function TabElement({
  active,
  width,
  index,
  label,
  onTabChange,
  handlerClickEvent,
  customStyle,
}) {
  return (
    <TabElementWrapper
      className={active ? 'active' : ''}
      width={width}
      key={index}
      onClick={() => {
        onTabChange(index);
        return handlerClickEvent(index);
      }}
      {...customStyle}
    >
      {label}
    </TabElementWrapper>
  );
}

TabElement.defaultProps = {
  handlerClickEvent: () => {},
  active: false,
  label: '',
  index: null,
  width: '100px',
  onTabChange: () => {},
};

TabElement.propTypes = {
  handlerClickEvent: PropTypes.func,
  active: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onTabChange: PropTypes.func,
};

const ButtonTabNav = styled.div`
  width: fit-content;
  position: relative;
`;

const ButtonTabIconWrapper = styled.div`
  margin-right: ${props => (props.last ? '0' : '8px')};
  display: inline-block;
`;

const TabsWrapper = styled.div`
  display: inline-block;
  * {
    transition: opacity 0.5s linear;
    opacity: ${props => (props.visible ? 1 : 0)};
    pointer-events: ${props => (props.visible ? 'initial' : 'none')};
  }
`;

const QueryInputWrapper = styled.div`
  position: absolute;
  top: -16px;
  width: calc(100% + 41px);
  pointer-events: ${props => (props.visible ? 'initial' : 'none')};
`;

const QueryInput = styled(Input)`
  transition: all 0.5s linear;
  opacity: ${props => (props.visible ? 1 : 0)};
  pointer-events: ${props => (props.visible ? 'initial' : 'none')};
  width: ${props => (props.visible ? '100%' : 0)};
  margin-right: 0;
  margin-left: auto;
  padding-right: 42px;
`;

const Icon = styled(IconNew).attrs({
  className: 'fa fa-search',
  fontSize: '20px',
})`
  cursor: pointer;
  transition: color 0.5s linear;
  display: inline-block;
  position: absolute;
  top: -16px;
  z-index: 10;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid transparent;

  &:hover {
    color: ${Rtheme.colors.primary};
    border-color: ${Rtheme.colors.primary};
  }
`;

const ButtonTabContent = styled.div``;

export function Tab({ children }) {
  return <div>{children}</div>;
}

Tab.defaultProps = {
  children: null,
};

Tab.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
};

export function Tabs(props) {
  const {
    children,
    query,
    queryValue,
    onQueryChange,
    onQueryClick,
    placeholder,
    customStyle,
  } = props;
  const [active, setActive] = useState(0);
  const [querying, setQuerying] = useState(false);

  const header = React.Children.map(children, (child, key) => {
    if (!React.isValidElement(child)) return;

    const { name, onTabChange } = child.props;
    // eslint-disable-next-line consistent-return
    return (
      <ButtonTabIconWrapper last={key === children.length - 1}>
        <TabElement
          label={name}
          index={key}
          handlerClickEvent={setActive}
          onTabChange={onTabChange}
          active={key === active}
          width={props.tabWidth}
          customStyle={customStyle}
        />
      </ButtonTabIconWrapper>
    );
  });

  const content = React.Children.map(children, (child, key) => {
    if (key === active) {
      return child;
    }
    return null;
  });

  const toggleOnQueryState = () => {
    setQuerying(!querying);
    onQueryClick(!querying);
  };

  return (
    <div>
      <ButtonTabNav className="tab-header">
        <TabsWrapper visible={!querying}>{header}</TabsWrapper>
        {query && (
          <Icon
            color={
              querying
                ? Rtheme.colors.primary
                : Rtheme.colors.fontColorSecondary
            }
            onClick={toggleOnQueryState}
          />
        )}
        <QueryInputWrapper visible={querying}>
          <QueryInput
            visible={querying}
            height="42px"
            placeholder={placeholder}
            onChange={onQueryChange}
            value={queryValue}
          />
        </QueryInputWrapper>
      </ButtonTabNav>
      {(!query || (query && !querying)) && (
        <ButtonTabContent className="tab-content">{content}</ButtonTabContent>
      )}
    </div>
  );
}

Tabs.defaultProps = {
  tabWidth: null,
  query: false,
  queryValue: '',
  onQueryChange: () => {},
  onQueryClick: () => {},
  placeholder: '予約ページタイトルで検索',
  children: null,
  customStyle: '',
};

Tabs.propTypes = {
  tabWidth: PropTypes.string,
  children(props, propName, componentName) {
    const prop = props[propName];
    let error = null;
    React.Children.forEach(prop, function validateChildrenPropType(child) {
      if (child && child.type !== Tab) {
        error = new Error(
          `\`${componentName}\` children should be of type \`Tab\`.`,
        );
      }
    });
    return error;
  },
  query: PropTypes.bool,
  queryValue: PropTypes.string,
  onQueryChange: PropTypes.func,
  onQueryClick: PropTypes.func,
  placeholder: PropTypes.string,
  customStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
