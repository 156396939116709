import styled from 'styled-components';
import { color, fontSize, fontWeight, letterSpacing } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

const Label = styled.label`
  font-size: 15px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  text-align: left;
  cursor: default;
  color: ${p =>
    p.primary
      ? themeGet('colors.fontColor')
      : themeGet('colors.fontColorSecondary')};
  white-space: ${p => p.nowrap && 'nowrap'};
  cursor: ${props => props.isCursor && 'pointer'};
  ${color}
  ${fontSize}
  ${fontWeight}
  ${letterSpacing}
`;

Label.defaultProps = {
  letterSpacing: '0.1px',
};

export default Label;
