import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme as Rtheme } from '../Utils/theme';

const TypeScopeButton = styled.div`
  cursor: pointer;
  color: ${props =>
    props.active ? Rtheme.colors.primary : Rtheme.colors.darkGray};
  border: solid 1px
    ${props =>
      props.active
        ? Rtheme.colors.borderColorFocus
        : Rtheme.colors.borderColorLight};
  width: ${props => (props.width ? props.width : '100px')};
  height: 32px;
  font-size: 14px;
  padding: 4px 0 0 0px;
  border-radius: 2px;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  line-height: 20px;
`;

export function ButtonTabIcon({
  active,
  width,
  index,
  label,
  handlerClickEvent,
}) {
  return (
    <TypeScopeButton
      active={active}
      width={width}
      key={index}
      onClick={() => handlerClickEvent(index)}
    >
      {label}
    </TypeScopeButton>
  );
}

ButtonTabIcon.defaultProps = {
  handlerClickEvent: () => {},
  active: false,
  label: '',
  width: '100px',
  index: undefined,
};

ButtonTabIcon.propTypes = {
  handlerClickEvent: PropTypes.func,
  active: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ButtonTabIcon;
