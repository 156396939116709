function generateTag(props, append) {
  if (props['data-test'] != null)
    return {
      'data-test': `${props['data-test']}${append != null ? `-${append}` : ''}`,
    };

  return {};
}

export { generateTag };
