import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import onClickOutside from 'react-onclickoutside';

import NavAccountImg from './NavAccountImg';
import NavDropDown from './NavDropDown';
import NavItem from './NavItem';
import { IconNew as Icon } from '../../../index';
import avatarDefault from '../../../Icons/avatar-default.png';

const NavItemHasDropDown = styled(NavItem)`
  line-height: 80px;
  cursor: pointer;
  ${space}
`;

function NavAccount({ marginTop, pr, ...restProps }) {
  const [expanded, setExpanded] = useState(false);

  function expand(e) {
    e.preventDefault();
    setExpanded(!expanded);
  }

  NavAccount.handleClickOutside = () => {
    setExpanded(false);
  };

  return (
    <NavItemHasDropDown onClick={e => expand(e)} pr={pr}>
      <div style={{ display: 'flex', marginTop }}>
        <NavAccountImg
          imgSrc={restProps.currentUser.icon_uri.url || avatarDefault}
        />
        <div style={{ marginTop: '-23px' }}>
          <Icon
            isCursor
            className="material-icons"
            color="#68878d"
            fontSize="18px"
          >
            expand_more
          </Icon>
        </div>
      </div>
      {expanded && <NavDropDown {...restProps} />}
    </NavItemHasDropDown>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => NavAccount.handleClickOutside,
};

NavAccount.defaultProps = {
  marginTop: '25px',
  dropdownTop: '65px',
  dropdownRight: '0px',
  showHr: true,
  showCompanyInfo: true,
  currentUser: {
    icon_uri: {},
    name: '橋本真理子',
    email: 'mariko@d-lighted.jp',
  },
  accountList: [
    { id: 1, label: 'item 1' },
    { id: 2, label: 'item 2' },
  ],
  companyList: [],
  dispatch: () => {},
  pr: '25px',
};

NavAccount.propTypes = {
  dispatch: PropTypes.func,
  marginTop: PropTypes.string,
  dropdownTop: PropTypes.string,
  dropdownRight: PropTypes.string,
  showHr: PropTypes.bool,
  showCompanyInfo: PropTypes.bool,
  accountList: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  companyList: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  currentUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  pr: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default onClickOutside(NavAccount, clickOutsideConfig);
