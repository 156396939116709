import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import { theme as Rtheme } from '../Utils/theme';
import { ErrorUI } from './ErrorUI';

const Wrapper = styled.div`
  margin-bottom: 5px;
  width: ${p => p.width};
  max-width: 100%;
  display: inline-block;
  color: ${Rtheme.colors.fontColorSecondary};
  font-size: 16px;
  font-weight: 500;
`;

const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SingleValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OptionTag = styled.div`
  content: '';
  position: relative;
  margin-left: -10px;
  width: ${props => props.selected ? "4px" : "0px"};
  background-color: ${Rtheme.colors.primary};
  border-radius: 0 2px 2px 0;
`;

const OptionLabel = styled.p`
  width: 100%;
  padding-left: ${props => props.selected ? "8px" : "12px"};
`;

const DropDownIcon = styled(FontAwesome)`
  color: ${props => props.menuIsOpen ? Rtheme.colors.lightGray : Rtheme.colors.fontColorSecondary};
  padding-right: 10px;
`;

const OptionIcon = styled(FontAwesome)`
  color: ${props => props.color || Rtheme.colors.primary};
  font-size: ${props => props.iconSize};
`;

const singleValueExtended = (provided) => {
  const { selectProps: { menuIsOpen } } = provided;
  return {
    lineHeight: '23px',
    color: menuIsOpen ? Rtheme.colors.lightGray : Rtheme.colors.fontColorSecondary,
    paddingLeft: '8px',
  };
};

const optionsValueExtended = () => ({
  color: Rtheme.colors.fontColor,
  fontSize: '15px',
  cursor: 'pointer',
  borderRadius: '4px',
  margin: '3px 0px',
  ':hover': {
    backgroundColor: Rtheme.colors.bgActive,
  },
});

const controlValueExtended = ({
  borderRadius,
  removeRightBorder,
  height,
  width,
  backgroundColor,
  border,
}) => {
  return {
    borderRadius,
    borderRightColor: removeRightBorder && 'transparent',
    borderTopRight: removeRightBorder ? '0px' : undefined,
    height,
    width,
    backgroundColor,
    border,
    boxShadow: 'none',
    fontSize: '15px',
    cursor: 'pointer',
  };
};

const placeholderValueExtended = (provided) => {
  const { selectProps: { menuIsOpen } } = provided;
  const color = menuIsOpen ? Rtheme.colors.fontColorSecondary : Rtheme.colors.lightGray
  return {
    color
  };
};

const colourStyles = (props) => {
  return {
    singleValue: (styles, provided) => ({ ...styles, ...singleValueExtended(provided) }),
    option: styles => ({ ...styles, ...optionsValueExtended() }),
    control: styles => ({
      ...styles,
      ...controlValueExtended(props),
    }),
    placeholder: (styles, provided) => ({
      ...styles,
      ...placeholderValueExtended(provided),
    }),
    menu: styles => ({ ...styles, marginTop: '3px' }),
    menuList: styles => ({ ...styles, padding: '5px' }),
    multiValue: styles => ({
      ...styles,
      color: Rtheme.colors.primary,
      lineHeight: '23px',
      backgroundColor: Rtheme.colors.bgAlternative,
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: Rtheme.colors.primary,
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: Rtheme.colors.primary,
      ':hover': {
        backgroundColor: Rtheme.colors.primary,
        color: Rtheme.colors.bgAlternative,
      },
    }),
  };
};

const DropdownIndicator = props => {
  const {
    selectProps: { menuIsOpen, value, iconPosition, dropdownIcon },
  } = props;
  const customIcon = value.icon ? value.icon : '';
  const renderDropdownIcon = () => {
    if (dropdownIcon) {
      return (
        <DropDownIcon
          name={dropdownIcon}
          className={`fas fa-${dropdownIcon}-circle fa-md`}
          role="button"
          menuIsOpen={menuIsOpen}
        />
      );
    }
    return iconPosition === 'left' ? null : (
      <DropDownIcon
        name={`${customIcon}`}
        className={`fas fa-${customIcon}-circle fa-md`}
        role="button"
        menuIsOpen={menuIsOpen}
      />
    );
  };
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {renderDropdownIcon()}
      </components.DropdownIndicator>
    )
  );
};

const { Option, SingleValue } = components;
const IconOption = props => {
  const {
    selectProps: { iconPosition, optionIconSize },
    data: { icon, label, selected, color },
  } = props;
  const customIcon = icon || '';
  return (
    <Option {...props}>
      <OptionWrapper>
        {iconPosition === 'left' ? (
          <>
            <OptionIcon
              name={`${customIcon}`}
              className={`fas fa-${customIcon}-circle fa-md`}
              role="button"
              color={color}
              iconSize={optionIconSize}
            />
            <OptionLabel selected={selected}>{label}</OptionLabel>
          </>
        ) : (
          <>
            <OptionTag selected={selected} />
            <OptionLabel selected={selected}>{label}</OptionLabel>
            <FontAwesome
              name={`${customIcon}`}
              className={`fas fa-${customIcon}-circle fa-md`}
              style={{ color: Rtheme.colors.primary }}
              role="button"
            />
          </>
        )}
      </OptionWrapper>
    </Option>
  );
};

const IconSingleValue = props => {
  const {
    selectProps: { iconPosition, optionIconSize },
    data: { icon, label, selected, color },
    children,
  } = props;
  const customIcon = icon || '';
  return (
    <SingleValue {...props}>
      {iconPosition === 'left' ? (
        <SingleValueWrapper>
          <OptionIcon
            name={`${customIcon}`}
            className={`fas fa-${customIcon}-circle fa-md`}
            role="button"
            color={color}
            iconSize={optionIconSize}
          />
          <OptionLabel selected={selected}>{label}</OptionLabel>
        </SingleValueWrapper>
      ) : (
        children
      )}
    </SingleValue>
  );
};

export const InputSelectWithIcon = ({ meta, ...props }) => {
  const { onChange, value, options } = props.input;
  return (
    <>
      <Wrapper width={props.width}>
        <Select
          onChange={onChange}
          theme={theme => {
            return {
              ...theme,
              colors: {
                ...theme.colors,
                primary: 'transparent',
                primary25: Rtheme.colors.bgActive,
                neutral20: Rtheme.colors.border,
                neutral80: Rtheme.colors.fontColor,
              },
              spacing: {
                baseUnit: 5,
              },
            };
          }}
          styles={colourStyles(props)}
          components={{
            DropdownIndicator,
            Option: IconOption,
            SingleValue: IconSingleValue,
            IndicatorSeparator: () => null,
          }}
          value={value}
          isSearchable={false}
          options={options}
          {...props}
        />
      </Wrapper>
      <ErrorUI meta={meta} />
    </>
  );
};

InputSelectWithIcon.defaultProps = {
  borderRadius: '5px',
  border: '0',
  height: '50px',
  width: '200px',
  borderOnFocus: true,
  backgroundColor: 'transparent',
  icon: 'sort-alpha-asc',
  iconPosition: 'right',
  optionIconSize: undefined,
  dropdownIcon: undefined,
  meta: { error: undefined, touched: undefined },
  input: {
    onChange: () => {},
  },
};

InputSelectWithIcon.propTypes = {
  borderRadius: PropTypes.string,
  border: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  borderOnFocus: PropTypes.bool,
  backgroundColor: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  optionIconSize: PropTypes.string,
  dropdownIcon: PropTypes.string,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
