import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CalendarHeader } from './CalendarHeader';
import { CalendarBody } from './CalendarBody';
import { getCalendarOptions, getHoursOfTheDay, moment } from './momentUtils';

/* eslint-disable react-hooks/exhaustive-deps */
function BigCalendar(props) {
  const {
    businessOpeningTime,
    businessClosingTime,
    duration,
    timeSlots,
    handleChange,
    isPreview,
  } = props;

  const newDate = moment();
  if (newDate.isoWeekday() > 5) {
    newDate.add(2, 'days');
  }

  const [options, setOptions] = useState(
    getCalendarOptions({ ...props, currentDate: newDate }),
  );

  useEffect(() => {
    if (options) {
      setOptions({
        ...options,
        getHoursOfTheDay: getHoursOfTheDay(
          businessOpeningTime,
          businessClosingTime,
          duration,
        ),
      });
    }
  }, [businessOpeningTime, businessClosingTime]);

  const setDateFromSmallCalendar = date => {
    setOptions({
      ...options,
      currentWeek: options.getThisWeek(moment(date)),
    });
  };

  const getNextWeek = () => {
    setOptions({
      ...options,
      currentWeek: options.getNextWeek(options.currentWeek.selectedDate),
    });
  };

  const getPrevWeek = () => {
    setOptions({
      ...options,
      currentWeek: options.getPreviousWeek(options.currentWeek.selectedDate),
    });
  };

  const onChange = (e, date, currentTime) => {
    e.preventDefault();
    const selected = {
      year: date.year(),
      month: date.format('M'),
      date: date.date(),
      day: date.day(),
      time: currentTime,
      nextHour: moment(currentTime, 'HH:mm')
        .add(props.duration, 'minutes')
        .format('HH:mm'),
    };
    handleChange(selected);
  };

  return (
    <CalendarHeader
      options={options}
      getPrevWeek={getPrevWeek}
      getNextWeek={getNextWeek}
      isTimeSlotLoading={!timeSlots.isSuccess}
      timeSlotsHasError={timeSlots.hasError}
      setDateFromSmallCalendar={setDateFromSmallCalendar}
    >
      <CalendarBody
        options={options}
        isTimeSlotLoading={!timeSlots.isSuccess}
        timeSlotsHasError={timeSlots.hasError}
        timeSlotsError={timeSlots.message}
        timeSlots={timeSlots}
        onChange={onChange}
        isPreview={isPreview}
      />
    </CalendarHeader>
  );
}

BigCalendar.defaultProps = {
  duration: 15,
  businessOpeningTime: '10:00',
  businessClosingTime: '18:00',
  timeSlots: { availableTimeslots: [], isSuccess: true },
  handleChange: () => {},
  isPreview: true,
};

BigCalendar.propTypes = {
  duration: PropTypes.number,
  handleChange: PropTypes.func,
  businessOpeningTime: PropTypes.string,
  businessClosingTime: PropTypes.string,
  timeSlots: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isPreview: PropTypes.bool,
};

export default BigCalendar;
