import styled from 'styled-components';

export const LocationModelContainer = styled.div`
  width: 100%;
  padding: 27px;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Footer = styled.div`
  display: block;
`;

export const Body = styled.div`
  padding-top: 10%;
  padding-left: 10px;
  padding-right: 10px;
`;
