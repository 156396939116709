import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import copylink from '../../Icons/copylink@3x.png';

const Imgs = styled.img`
  height: 14px;
  width: 14px;
  padding-right: 0.8em;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${p => p.justifyContent};
  text-align: center;
`;

export const Icon = ({ justifyContent, text }) => {
  return (
    <IconWrapper justifyContent={justifyContent}>
      <Imgs src={copylink} />
      {text}
    </IconWrapper>
  );
};

Icon.defaultProps = {
  justifyContent: 'center',
  text: undefined,
};

Icon.propTypes = {
  justifyContent: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
