import React from 'react';
import PropTypes from 'prop-types';
import { Div } from './Helper';
import { Button, Heading } from '../../Atoms';
import { moment, isTimeEarlier } from './momentUtils';

export function SingleDate({ day, date, isDateEarlier, getJapaneseDay }) {
  return (
    <Div
      display={['flex', 'block']}
      alignItems="baseline"
      textAlign={[null, 'center']}
      width="100%"
    >
      <Div width={[null, '100%']}>
        <Heading
          size={1}
          color={`${
            (isDateEarlier(day, moment()) === 'earlier' &&
              'rgba(20, 44, 43, 0.2)') ||
            (isDateEarlier(day, moment()) === 'equal' && '#00bbb5')
          }`}
        >
          {date}
        </Heading>
      </Div>
      <Div ml={['7px', '0px']} mb="10px" width={[null, '100%']}>
        <Heading
          size={5}
          color={`${
            (isDateEarlier(day, moment()) === 'earlier' &&
              'rgba(20, 44, 43, 0.2)') ||
            (isDateEarlier(day, moment()) === 'equal' && '#00bbb5')
          }`}
        >
          {getJapaneseDay(day.day())}
        </Heading>
      </Div>
    </Div>
  );
}

SingleDate.defaultProps = {
  day: null,
  date: [],
};

SingleDate.propTypes = {
  day: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  date: PropTypes.number,
  getJapaneseDay: PropTypes.func.isRequired,
  isDateEarlier: PropTypes.func.isRequired,
};

export const TimeSlots = ({
  hoursOfTheDay,
  timeSlots,
  day,
  handleChange,
  isPreview,
  dayIndex,
}) => {
  return (
    <Div
      noScrollbar
      overflowX="auto"
      display="flex"
      flexDirection={['row', 'column']}
      justifyContent={[null, 'space-between']}
      width="100%"
    >
      {hoursOfTheDay.slice(0, 7).map((currentTime, index) => {
        return (
          <Div key={currentTime} margin={['0px 5px', '5px 0px']}>
            <Button
              outlined
              invertOnHover
              disabled={
                isPreview
                  ? ([0, 2, 4].includes(dayIndex)
                      ? [1, 3, 4, 7].includes(index)
                      : [0, 2, 5].includes(index)) ||
                    isTimeEarlier(
                      moment(
                        `${day.format('YYYY-MM-DD')}-${currentTime}`,
                        'YYYY-MM-DD-HH:mm',
                      ),
                      moment(),
                    ) === 'earlier'
                  : !timeSlots.includes(currentTime)
              }
              fontSize={['20px', '15px', '20px']}
              borderRadius="3px"
              width={['95px', '100%']}
              height={['51px', '63px']}
              boxShadow="0 1px 6px 0 #d2e9e7"
              onClick={e => handleChange(e, day, currentTime)}
              fontWeight="bold"
            >
              {currentTime}
            </Button>
          </Div>
        );
      })}
    </Div>
  );
};

TimeSlots.defaultProps = {
  day: null,
  timeSlots: [],
  hoursOfTheDay: [],
  isPreview: true,
  dayIndex: 0,
};

TimeSlots.propTypes = {
  day: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  handleChange: PropTypes.func.isRequired,
  timeSlots: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  hoursOfTheDay: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  isPreview: PropTypes.bool,
  dayIndex: PropTypes.number,
};
