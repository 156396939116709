const breakpoints = ['600px', '768px', '992px'];

export const theme = {
  breakpoints,
  colors: {
    primary: '#00bbb5',
    primaryTransparent6: '#00bbb510',
    bgAlternative: '#d2e9e7',
    bgActive: '#e6f4ef',
    bgError: '#e84c3d',
    bgHover: '#259590',
    bgNavy: '#6363f3',
    bgWarning: '#ffdb3f',
    bg: '#f7f9f9',
    black: '#000000',
    white: '#ffffff',
    lightWhite: '#ffffff',
    whiteTransparent: '#ffffff66',
    darkGray: '#484e62',
    normalGray: '#8e8e8e',
    lightGray: '#9bb0b5',
    shadow: '#d2e9e7',
    shadowTransparent: '#d2e9E766',
    boxShadow: '#00000026',
    ghost: '#142c2b33',
    border: '#e5e5e5',
    borderColor: '#d2e9e7',
    borderColorLight: '#e7e9ea',
    borderColorFocus: '#00bbb5',
    fontColor: '#314143',
    fontColorSecondary: '#68878d',
    fontColorFocus: '#314143',
    fontColorError: '#b00020',
    fontColorDisabled: '#999999',
  },
};
