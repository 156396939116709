import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar, Label } from '../Atoms';
import userDefault from '../../Icons/userDefault.png';
import { theme as Rtheme } from '../Utils/theme';

const AvatarWrapper = styled.div`
  padding-right: 10px;
  border-radius: 100px;
  border: solid 1px ${Rtheme.colors.borderColor};
  text-align: center;
  background-color: ${Rtheme.colors.lightWhite};
  font-size: 14px;
  color: ${Rtheme.colors.primary};
  display: inline-flex;
  margin: 0 20px 20px 0;
`;

const AvatartExtended = styled(Avatar)`
  width: 40px;
  height: 40px;
  background-color: ${Rtheme.colors.lightGray};
  color: ${Rtheme.colors.fontColor};
`;

const AvatarLabel = styled(Label)`
  margin: 10px;
  font-size: 14px;
`;

const CrossIcon = styled.div`
  position: relative;
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 10px auto;
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 11px;
    right: 3px;
    background-color: ${Rtheme.colors.normalGray};
    width: 16px;
    height: 1px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 11px;
    right: 3px;
    background-color: ${Rtheme.colors.normalGray};
    width: 16px;
    height: 1px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
`;

export function SelectedMember({ id, src, label, showCrossIcon, onClick }) {
  return (
    <AvatarWrapper key={id}>
      <AvatartExtended src={src || userDefault} />
      <AvatarLabel color="grey">{label}</AvatarLabel>
      {showCrossIcon && <CrossIcon onClick={onClick} />}
    </AvatarWrapper>
  );
}

SelectedMember.defaultProps = {
  id: '',
  src: '',
  label: 'Full Name',
  showCrossIcon: true,
  onClick: () => {},
};

SelectedMember.propTypes = {
  id: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showCrossIcon: PropTypes.bool,
  onClick: PropTypes.func,
};
