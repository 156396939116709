import React from 'react';
import FontAwesome from "react-fontawesome";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ErrorText } from '../Atoms';
import { generateTag } from '../Utils/test';

const Wrapper = styled.div`
  position: absolute;
`;

const WarningIcon = styled(FontAwesome)`
  width: 16px;
  height: 16px;
  margin-left: 10px;
  position: relative;
  margin-top: ${props => props.top}px;
  color: #e84c3d;
`;

const ErrorWrapper = styled.div`
  padding-top: ${props => props.mt ? props.mt : "20px"};
  margin-left: ${props => props.ml ? props.ml : "14px"};
`;

export function ErrorUI({ meta: { touched, error, warning }, color, mt, ml, icon, whiteSpace, ...restProps }) {
  return (
    <Wrapper {...generateTag(restProps, 'wrapper')}>
      {touched &&
        ((error && icon && (
          <ErrorWrapper mt={mt} ml={ml}>
            <WarningIcon 
              name={`${icon}-circle`} 
              className={`fas fa-${icon}-circle fa-lg`}
              role="button"
            />
            <ErrorText {...generateTag(restProps, 'error_text')} color={color} mt={mt} ml={ml} whiteSpace={whiteSpace}>
              {error}
            </ErrorText>
          </ErrorWrapper>
        )) || ((error && !icon && (
          <ErrorText {...generateTag(restProps, 'error_text')} color={color} mt={mt} ml={ml} whiteSpace={whiteSpace}>
            {error}
          </ErrorText>
        )))||
          (warning && (
            <span {...generateTag(restProps, 'warning_text')}>{warning}</span>
          )))}
    </Wrapper>
  );
}

ErrorUI.defaultProps = {
  meta: {
    touched: undefined,
    error: undefined,
    warning: undefined,
  },
};

ErrorUI.propTypes = {
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
