import styled from 'styled-components';
import { theme as Rtheme } from '../Utils/theme';

const ErrorText = styled.span`
  margin-top: ${props => props?.mt ? props.mt : "0px"};
  padding-left: 10px;
  display: inline-block;
  white-space: ${props => props.whiteSpace ? props.whiteSpace : "nowrap"};
  color: ${props => props?.color ? props.color : Rtheme.colors.fontColorError};
`;

export default ErrorText;
