import styled from 'styled-components';
import { color, margin, typography } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { theme as Rtheme } from '../Utils/theme';

export const Heading = styled.p`
  ${margin}
  ${color}
  ${props => {
    switch (props.size) {
      case 1:
        return `
          font-size: 28px;
          font-weight: 500;
          line-height: 1.6;
          color: ${themeGet('colors.fontColor')};
        `;
      case 2:
        return `
          font-size: 20px;
          font-weight: 500;
          line-height: 1.45;
          letter-spacing: 1px; 
          color: ${themeGet('colors.fontColor')};
        `;
      case 3:
        return `
          font-size: 15px;
          line-height: 1.46;
          color: ${themeGet('colors.fontColorSecondary')};
        `;
      case 4:
        return `
          font-size: 15px;
          line-height: 1.6;
          letter-spacing: 0.1px; 
          color: ${themeGet('colors.fontColor')};
        `;
      case 5:
        return `
          font-size: 13px;
          font-weight: 500;
          line-height: 1.46;
          color: ${themeGet('colors.fontColor')};
        `;
      case 6:
        return `
          font-size: 11px;
          font-weight: 500;
          color: ${themeGet('colors.fontColor')};
          letter-spacing: 0.55px;
        `;
      default:
        return '';
    }
  }}
  color:${p => (p.muted ? themeGet('colors.ghost') : undefined)};
  ${typography};
`;

export const LinkText = styled.p`
  ${typography}
  ${color}
  ${margin}
  cursor: pointer;
`;

LinkText.defaultProps = {
  fontSize: '16px',
  color: Rtheme.colors.primary,
};
