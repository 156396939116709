import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { layout } from 'styled-system';
import { range } from 'lodash';
import { theme as Rtheme } from '../Utils/theme';

const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 0;
  justify-content: center;
  ${layout}
`;

const PaginationControl = styled.button`
  height: 40px;
  width: 40px;
  border: 1px solid ${Rtheme.colors.borderColorLight};
  border-radius: 3px;
  color: ${Rtheme.colors.lightGray};
  background: ${Rtheme.colors.white};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 6px;
  cursor: pointer;

  &:hover {
    color: ${Rtheme.colors.primary};
  }

  &.active {
    border-color: ${Rtheme.colors.primary};
    color: ${Rtheme.colors.primary};
  }

  &.arrow {
    border: none;
    font-size: 20px;
    color: ${Rtheme.colors.primary};

    &:disabled {
      color: ${Rtheme.colors.bgActive} !important;
      cursor: default;
    }
  }
`;

const PaginationEllipsis = styled.div`
  height: 40px;
  width: 40px;
  color: ${Rtheme.colors.lightGray};
  background: ${Rtheme.colors.white};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  pointer-events: none;
`;

function getPageRange(page, total, size = 7) {
  if (page < size) {
    return range(1, Math.min(size + 1, total + 1), 1);
  }
  if (page > total - size) {
    return range(total - size + 1, total + 1, 1);
  }
  return range(
    page - Math.floor(size / 2),
    Math.min(total + 1, page + Math.round(size / 2)),
    1,
  );
}

function Pagination({
  onClick,
  currentPage,
  totalPages,
  angleRight,
  angleLeft,
  paginationDisplayStyle,
}) {
  if (totalPages <= 1) return null;

  const pageRange = getPageRange(currentPage, totalPages);
  return (
    <PaginationWrapper display={paginationDisplayStyle}>
      <PaginationControl
        disabled={!(currentPage > 1)}
        className="arrow"
        onClick={() => onClick(currentPage - 1)}
      >
        {angleLeft}
      </PaginationControl>
      {pageRange[0] > 1 && <PaginationEllipsis>…</PaginationEllipsis>}
      {pageRange.map(value => (
        <PaginationControl
          key={value}
          className={value === currentPage ? 'active' : ''}
          onClick={() => onClick(value)}
        >
          {value}
        </PaginationControl>
      ))}
      {pageRange[pageRange.length - 1] < totalPages && (
        <PaginationEllipsis>…</PaginationEllipsis>
      )}
      <PaginationControl
        disabled={!(currentPage < totalPages)}
        className="arrow"
        onClick={() => onClick(currentPage + 1)}
      >
        {angleRight}
      </PaginationControl>
    </PaginationWrapper>
  );
}

Pagination.defaultProps = {
  onClick: () => {},
  currentPage: 0,
  totalPages: 0,
  angleRight: '>',
  angleLeft: '<',
  paginationDisplayStyle: 'flex',
};

Pagination.propTypes = {
  onClick: PropTypes.func,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  angleRight: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  angleLeft: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  paginationDisplayStyle: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
};

export default Pagination;
