import styled from 'styled-components';

const NavItem = styled.div`
  color: #e5e5e5;
  float: left;
  padding-right: 25px;
  > * {
    line-height: 80px;
  }
`;

export default NavItem;
