import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import onClickOutside from 'react-onclickoutside';

import NavAccountImg from './NavAccountImg';
import NavItem from './NavItem';
import { IconNew as Icon } from '../../../index';
import avatarDefault from '../../../Icons/avatar-default.png';
import { Avatar, Col, Hr, Label, Row } from "../../../index";
import { theme as Rtheme } from '../../Utils/theme';

const NavItemHasDropDown = styled(NavItem)`
  line-height: 80px;
  cursor: pointer;
  ${space}
`;

const DropDownWrapper = styled.div`
  position: absolute;
  display: block;
  top: ${p => p.top};
  right: ${p => p.right};
  padding: 20px 20px 0;
  width: 210px;
  height: auto;
  background: #ffffff;
  box-shadow: 0 20px 40px 10px ${Rtheme.colors.shadow};
  border-radius: 3px;
`;

function AccountNavBar({ marginTop, pr, children, ...restProps }) {
  const [expanded, setExpanded] = useState(false);

  function expand(e) {
    e.preventDefault();
    setExpanded(!expanded);
  }

  AccountNavBar.handleClickOutside = () => {
    setExpanded(false);
  };

  return (
    <NavItemHasDropDown onClick={e => expand(e)} pr={pr}>
      <div style={{ display: 'flex', marginTop }}>
        <NavAccountImg
          imgSrc={restProps.currentUser.icon_uri.url || avatarDefault}
        />
        <div style={{ marginTop: '-23px' }}>
          <Icon
            isCursor
            className="material-icons"
            color="#68878d"
            fontSize="18px"
          >
            expand_more
          </Icon>
        </div>
      </div>
      {expanded && <DropDownWrapper top={restProps.dropdownTop} right={restProps.dropdownRight}>
        <Row>
          <Col xs={2}>
            <Avatar
              height="40px"
              width="40px"
              src={restProps.currentUser.icon_uri.url || avatarDefault}
            />
          </Col>
          <Col xs={10}>
            <Row ml="10px" mt="7px">
              <Label isCursor fontSize="16px">
                {restProps.currentUser.name}
              </Label>
            </Row>
            <Row ml="10px">
              <Label isCursor fontSize="11px" color="#8ca0b3">
                {restProps.currentUser.email}
              </Label>
            </Row>
          </Col>
        </Row>
        <div
          style={{
            marginLeft: '7px',
            marginTop: '-23px',
            marginBottom: '30px',
          }}
        >
          {children}
        </div>
      </DropDownWrapper>}
    </NavItemHasDropDown>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => AccountNavBar.handleClickOutside,
};

AccountNavBar.defaultProps = {
  marginTop: '25px',
  dropdownTop: '65px',
  dropdownRight: '0px',
  showHr: true,
  currentUser: {
    icon_uri: {},
    name: '橋本真理子',
    email: 'mariko@d-lighted.jp',
  },
  dispatch: () => {},
  pr: '25px',
};

AccountNavBar.propTypes = {
  dispatch: PropTypes.func,
  marginTop: PropTypes.string,
  dropdownTop: PropTypes.string,
  dropdownRight: PropTypes.string,
  currentUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  pr: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default onClickOutside(AccountNavBar, clickOutsideConfig);
