import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import { GlobalStyle as _GlobalStyle } from './GlobalStyle';
import { theme as _theme } from './theme';

export const ReceptionistThemeProvider = ({
  GlobalStyle,
  extraConfiguration,
  theme,
  children,
}) => {
  return (
    <>
      <GlobalStyle />
      {extraConfiguration && <extraConfiguration />}
      <ThemeProvider theme={theme}>
        <ModalProvider>{children}</ModalProvider>
      </ThemeProvider>
    </>
  );
};

ReceptionistThemeProvider.defaultProps = {
  GlobalStyle: _GlobalStyle,
  theme: _theme,
  extraConfiguration: undefined,
  children: undefined,
};

ReceptionistThemeProvider.propTypes = {
  GlobalStyle: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  theme: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  extraConfiguration: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
};
