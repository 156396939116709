import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { space } from 'styled-system';
import { theme as Rtheme } from '../Utils/theme';

const loadingAnimation = keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(360deg); }
`;

const loadingCircleAnimation = keyframes`
  0% { stroke-dashoffset: 0 }
  100% { stroke-dashoffset: -600; }
`;

const LoaderWrapper = styled.svg`
    display: block;
    width: 90px;
    animation: ${loadingAnimation} 3s linear infinite;
    fill: ${Rtheme.colors.normalGray};
    ${space}
    & .loading-inner {
      animation: ${loadingCircleAnimation} 2s linear infinite;
      stroke: ${Rtheme.colors.primary};
      fill: transparent;
      stroke-dashoffset: 0;
      stroke-dasharray: 300;
      stroke-width: 10;
      stroke-miterlimit: 10;
      stroke-linecap: round;
    }
  }
`;

function Loader({ className, ...rest }) {
  return (
    <LoaderWrapper
      x="0px"
      y="0px"
      viewBox="0 0 150 150"
      className={className}
      {...rest}
    >
      <circle className="loading-inner" cx="75" cy="75" r="60" />
    </LoaderWrapper>
  );
}

Loader.defaultProps = {
  className: undefined,
};

Loader.propTypes = {
  className: PropTypes.string,
};

export { Loader };
