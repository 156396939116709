import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FadeIn } from 'animate-css-styled-components';

import { Col, Label, Row } from '../../../index';

const IconSVG = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 25px;
`;

function AccountNavDropDown({
  item,
  mt,
  mb,
  fontSize,
}) {
  return (
    <FadeIn duration="0.2s">
      <Row
        key={`dropdown-${item.label}`}
        mt={mt}
        mb={mb}
        isCursor
        onClick={item.onClick}
      >
        <Col display="flex" alignItems="center">
          <IconSVG src={item.icon} />
          <Label isCursor fontSize={fontSize}>
            {item.label}
          </Label>
        </Col>
      </Row>
    </FadeIn>
  );
}

AccountNavDropDown.defaultProps = {
  item: {},
  mt:"15px",
  mb:"0px",
  fontSize: "16px",
};

AccountNavDropDown.propTypes = {
  mt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // eslint-disable-line react/forbid-prop-types
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // eslint-disable-line react/forbid-prop-types
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // eslint-disable-line react/forbid-prop-types
  item: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default AccountNavDropDown;
