import React from 'react';
import PropTypes from 'prop-types';
import { Div, Hr } from './Helper';
import { SingleDate, TimeSlots } from './Day';
import { moment } from './momentUtils';

export function CalendarBody(props) {
  const {
    onChange,
    timeSlots,
    options: {
      isDateEarlier,
      calculateAvailableTime,
      getHoursOfTheDay,
      getJapaneseDay,
      currentWeek: { week },
    },
  } = props;

  const availableTimeSlots = calculateAvailableTime(
    timeSlots.availableTimeslots,
  );

  /** if day has empty slots return empty array explicit */
  function handleEmptySlotDays(day, timeSlotsAvailable) {
    if (timeSlotsAvailable[day.format('MM-DD-yyyy')] === undefined) {
      return [];
    }
    return timeSlotsAvailable[day.format('MM-DD-yyyy')];
  }

  return (
    <Div
      display="flex"
      flexDirection={['column', 'row']}
      justifyContent="space-between"
      width="100%"
    >
      {week.map((day, index) => {
        return (
          <Div
            key={day.format('lll')}
            width="100%"
            bg={`${isDateEarlier(day, moment()) === 'equal' && '#e6f4ef'}`}
            px="15px"
            borderRadius={['0px', '10px']}
            pb={[null, '35px']}
          >
            <Div width="100%" px={['-15px', '0px']}>
              <Div
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
              >
                <SingleDate
                  date={day.date()}
                  day={day}
                  getJapaneseDay={getJapaneseDay}
                  isDateEarlier={isDateEarlier}
                />
                <TimeSlots
                  hoursOfTheDay={getHoursOfTheDay}
                  day={day}
                  dayIndex={index}
                  timeSlots={handleEmptySlotDays(day, availableTimeSlots)}
                  handleChange={onChange}
                  isPreview={props.isPreview}
                />
              </Div>
              <Hr
                display={['block', 'none']}
                border="none"
                height="2px"
                bg="#d2e9e7"
                mb="0px"
              />
            </Div>
          </Div>
        );
      })}
    </Div>
  );
}

CalendarBody.defaultProps = {
  timeSlots: {},
  options: {},
  isPreview: true,
};

CalendarBody.propTypes = {
  onChange: PropTypes.func.isRequired,
  timeSlots: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  options: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isPreview: PropTypes.bool,
};
