import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  fontWeight,
  width,
  minWidth,
  height,
  fontSize,
  color,
  space,
  borders,
  borderRadius,
  position,
  shadow,
  textAlign,
} from 'styled-system';
import { theme as Rtheme } from '../Utils/theme';

export const Button = styled.button`
  padding-left: ${props =>
    props.isIconSvgPresent || props.isIconPresent ? '35px' : '5px'};
  box-sizing: border-box;
  cursor: pointer;
  &:before {
    position: absolute;
    font-family: ${props => props.iconFontFamily || 'serif'};
    content: '${props => props.iconContent}';
    display: ${props => (props.isIconPresent ? 'block' : 'none')};
    color: ${props => props.iconColor};
    top: 0;
    left: 20px;
    line-height: ${props => props.height};
    font-size: ${props => props.iconSize};
    font-weight: 100;
  }
  ${position}
  ${width}
  ${minWidth}
  ${height}
  ${fontSize}
  ${color}
  ${space}
  ${borders}
  ${borderRadius}
  ${textAlign}
  ${fontWeight}
  ${shadow}
  &:hover {
    background-color: ${Rtheme.colors.bgHover};
  }
  &:disabled {
    cursor: no-drop;
    opacity: 0.4;
    background-color: ${Rtheme.colors.primary};

    & img {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }
  ${props =>
    props.outlined &&
    `
    background-color: ${Rtheme.colors.white};
    color: ${Rtheme.colors.primary};
    border: 1px solid ${Rtheme.colors.borderColorFocus};
    &:hover {
      background-color: ${Rtheme.colors.borderColorLight};
    }
    &:disabled {
      opacity: 1;
      color: ${Rtheme.colors.ghost} !important;
      border: solid 2px ${Rtheme.colors.shadowTransparent} !important;
      background: ${Rtheme.colors.whiteTransparent} !important;
      box-shadow: none;
    }
  `};
  ${props =>
    props.invertOnHover &&
    `
    &:hover {
      background-color: ${Rtheme.colors.primary};
      color: white;
    }
  `};
  &:focus {
    outline: none;
  }
`;

Button.defaultProps = {
  iconContent: '+',
  iconColor: Rtheme.colors.white,
  iconSize: '30px',
  borderRadius: '5px',
  position: 'relative',
  height: '50px',
  color: 'white',
  bg: Rtheme.colors.primary,
  fontWeight: 'normal',
  fontSize: '16px',
  textAlign: 'center',
  width: '100%',
  minWidth: '120px',
  border: 'none',
};

Button.propTypes = {
  ...position.propTypes,
  ...width.propTypes,
  ...minWidth.propTypes,
  ...height.propTypes,
  ...color.propTypes,
  ...space.propTypes,
  ...borders.propTypes,
  ...textAlign.propTypes,
  ...fontWeight.propTypes,
  border: PropTypes.string,
  submitFailed: PropTypes.bool,
};
