import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import styled, { keyframes, css } from 'styled-components';
import { width, space, position } from 'styled-system';
import { Input, IconNew } from '../Atoms';
import { Tab, TabElement } from './Tabs';
import { theme as Rtheme } from '../Utils/theme';
import { InputSelect } from './InputSelect';

const loadingAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
`;

const ButtonTabNav = styled.div`
  width: fit-content;
  position: relative;
  ${width}
`;

const ButtonTabIconWrapper = styled.div`
  margin-right: ${props => (props.last ? '0' : '8px')};
  display: inline-block;
`;

const TabsWrapper = styled.div`
  display: inline-block;
  * {
    transition: opacity 0.5s linear;
    opacity: ${props => (props.visible ? 1 : 0)};
    pointer-events: ${props => (props.visible ? 'initial' : 'none')};
  }
  ${space}
`;

const QueryInputWrapper = styled.div`
  position: absolute;
  top: -16px;
  width: calc(100% + 41px);
  pointer-events: ${props => (props.visible ? 'initial' : 'none')};
  ${width}
  ${space}
`;

const SortWrapper = styled.div`
  display: inline-block;
  position: absolute;
  width: 100%;
  top: ${props => (props.mt ? props.mt : '-19px')};
  margin-left: ${props => (props.ml ? props.ml : '61px')};
  right: ${props => (props.right ? props.right : 'none')};
  ${width}
  ${space}
`;

const QueryInput = styled(Input)`
  transition: all 0.5s linear;
  opacity: ${props => (props.visible ? 1 : 0)};
  pointer-events: ${props => (props.visible ? 'initial' : 'none')};
  width: ${props => (props.visible ? '100%' : 0)};
  margin-right: 0;
  margin-left: auto;
  padding-right: 42px;
`;

const Icon = styled(IconNew).attrs({
  className: 'fa fa-search',
  fontSize: '20px',
})`
  cursor: pointer;
  transition: color 0.5s linear;
  display: inline-block;
  position: absolute;
  top: -16px;
  z-index: 10;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid transparent;

  &:hover {
    color: ${Rtheme.colors.primary};
    border-color: ${Rtheme.colors.primary};
  }
  ${position}
`;

const SearchOptions = styled.div`
  position: absolute;
  top: 0;
  right: 41px;
  z-index: 10;
  border: 0;
  border-radius: 5px;
  opacity: ${props => (props.visible ? 'none' : 0)};
  animation: ${props =>
    props.visible &&
    css`
      ${loadingAnimation} 1s ease-in forwards;
    `};
  width: ${props => (props.width ? props.width : '160px')};
`;

const ButtonTabContent = styled.div``;
export function UncontrolledTabs(props) {
  const {
    children,
    query,
    queryValue,
    onQueryChange,
    onQueryClick,
    onSearchOptionChange,
    placeholder,
    tab,
    setTab,
    querying,
    setQuerying,
    customStyle,
    tabWidth,
    customInputStyle,
    customTabStyle,
    customIconStyle,
    tabSideComponent,
    customTabSideStyle,
    customInputOptionsStyle,
    forceShow,
  } = props;

  const toggleOnQueryState = () => {
    setQuerying(!querying);
    onQueryClick(!querying);
  };
  
  const nonQueryTabs = [];
  const elementRef = useRef();
  const querySearchRef = useRef();

  useEffect(() => {
    function handleClick(e) {
      if ((elementRef && elementRef.current) || (querySearchRef && querySearchRef.current)) {
        const ref = elementRef.current;
        if (!ref.contains(e.target) && !querySearchRef?.current?.innerHTML.includes(e.target.innerHTML)) {
          const condition = querying === true && isEmpty(queryValue);
          if (condition) {
            toggleOnQueryState();
          }
        }
      }
    }
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
    /* eslint-disable-next-line  react-hooks/exhaustive-deps */
  }, [querying, queryValue]);

  const header = React.Children.map(children, (child, key) => {
    if (!React.isValidElement(child)) return;

    const { name, onTabChange, noQuery } = child.props;
    
    if (noQuery) nonQueryTabs.push(key);
    // eslint-disable-next-line consistent-return
    return (
      <ButtonTabIconWrapper last={key === children.length - 1}>
        <TabElement
          label={name}
          index={key}
          handlerClickEvent={setTab}
          onTabChange={onTabChange}
          active={key === tab}
          customStyle={customStyle}
        />
      </ButtonTabIconWrapper>
    );
  });

  const content = React.Children.map(children, (child, key) => {
    if (key === tab) {
      return child;
    }
    return null;
  });

  return (
    <div>
      <ButtonTabNav className="tab-header" width={tabWidth}>
        <TabsWrapper visible={!querying} {...customTabStyle}>
          {header}
        </TabsWrapper>
        {!nonQueryTabs.includes(tab) && query && (
          <Icon
            color={
              querying
                ? Rtheme.colors.primary
                : Rtheme.colors.fontColorSecondary
            }
            onClick={toggleOnQueryState}
            right={props.iconPosition}
            querying={querying}
            {...customIconStyle}
          />
        )}
        <QueryInputWrapper
          ref={elementRef}
          visible={querying}
          {...customInputStyle}
        >
          <QueryInput
            visible={querying}
            height="42px"
            placeholder={placeholder}
            onChange={onQueryChange}
            value={queryValue}
            querying={querying}
          />
          <SearchOptions 
            visible={querying}
            width={customInputOptionsStyle.width}
            ref={querySearchRef}
          >
            <InputSelect
              {...customInputOptionsStyle}
              border="none !important"
              mt="1px"
              visible={querying}
              options={props.searchOptions}
              defaultValue={props.defaultSearchOption}
              optionColor={Rtheme.colors.fontColorSecondary}
              isClearable={false}
              isMulti={false}
              isSearchable={false}
              onChange={(e) => (onSearchOptionChange(e))}
            />
          </SearchOptions>
        </QueryInputWrapper>
        {tabSideComponent &&
          <SortWrapper
            {...customTabSideStyle}
          >
            {tabSideComponent}
          </SortWrapper>
        }
      </ButtonTabNav>
      {forceShow && (
        <ButtonTabContent className="tab-content">{content}</ButtonTabContent>
      )}
    </div>
  );
}

UncontrolledTabs.defaultProps = {
  tabWidth: 'fit-content',
  query: false,
  queryValue: '',
  onQueryChange: () => {},
  onQueryClick: () => {},
  onSearchOptionChange: () => {},
  placeholder: '予約ページタイトルで検索',
  children: null,
  customStyle: '',
  iconPosition: '',
  customInputStyle: '',
  customTabStyle: '',
  customIconStyle: '',
  tabSideComponent: '',
  customTabSideStyle: '',
  customInputOptionsStyle: '',
  forceShow: true,
};

UncontrolledTabs.propTypes = {
  tabWidth: PropTypes.string,
  children(props, propName, componentName) {
    const prop = props[propName];
    let error = null;
    React.Children.forEach(prop, function validateChildrenPropType(child) {
      if (child && child.type !== Tab) {
        error = new Error(
          `\`${componentName}\` children should be of type \`Tab\`.`,
        );
      }
    });
    return error;
  },
  query: PropTypes.bool,
  queryValue: PropTypes.string,
  onQueryChange: PropTypes.func,
  onQueryClick: PropTypes.func,
  onSearchOptionChange: PropTypes.func,
  placeholder: PropTypes.string,
  tab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
  querying: PropTypes.bool.isRequired,
  setQuerying: PropTypes.func.isRequired,
  customStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customInputStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customTabStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customIconStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tabSideComponent: PropTypes.elementType,
  customTabSideStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customInputOptionsStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  forceShow: PropTypes.bool,
};
