import styled from 'styled-components';
import { color } from 'styled-system';

export const Wrapper = styled.div`
  background-color: #dbf9f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
  border-radius: 10px;
  border: 10px solid #b3dec1;
  height: 20%;
  ${color}
`;

export const ColumnWrapper = styled(Wrapper)`
  flex-direction: column;
  align-items: flex-start;
`;
