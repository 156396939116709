import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tag from './Tag';

const Wrapper = styled.div`
  display: inline-grid;
  position: relative;
  left: 8px;
`;

const IconWrapper = styled.div`
  display: inline-block;
  box-sizing: border-box;

  &:hover + .display-text {
    visibility: visible;
    opacity: 1;
  }
`;

const Anchor = styled.div`
  visibility: hidden;
  content: '';
  transition: 0.2s;
  position: initial;
  z-index: 2;

  &:hover {
    transition: 0s;
    visibility: visible;
    opacity: 1;
  }
`;

export const DisplayText = styled.div`
  background-color: white;
  border: 1px solid #e5e5e5;
  color: #314143;
  max-width: ${props => props.width}px;
  width: max-content;
  left: ${props => {
    if(props.position === 'right') {
      return 100;
    }
    if(props.position === 'bottom') {
      return -400;
    }
    return
  }}%;
  box-shadow: 0 20px 40px 10px rgba(210, 224, 233, 0.4);
  padding: 12px;
  border-radius: 6px;
  position: absolute;
  font-size: 12px;
  z-index: 2;
  top: ${props => {
    if(props.position === 'right') {
      return -10;
    }
    if(props.position === 'bottom') {
      return 20;
    }
    return
  }}px;
  font-weight: 200;

  @media (max-width: 599px) {
    top: 22px;
    left: ${props =>
      props.windowWidth <= 599 && props.left
        ? `${-props.left + 13}px`
        : 'inherit'};
    max-width: calc(100vw - 55px);
    width: calc(100vw - 55px);
  }
`;

const TextBody = styled.div``; // This component is just for readability

function LabelWithTooltip({
  bgColor,
  fontColor,
  labelText,
  tooltipText,
  width,
  position,
}) {
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowSize);
    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, []);

  return (
    <Wrapper ref={elem => setOffsetLeft(elem?.offsetLeft)}>
      <IconWrapper>
        <Tag bgColor={bgColor} fontColor={fontColor}>
          {labelText}
        </Tag>
      </IconWrapper>
      <Anchor className="display-text">
        <DisplayText
          position={position}
          width={width}
          left={windowWidth <= 599 ? offsetLeft : null}
          windowWidth={windowWidth}
        >
          <TextBody>{tooltipText}</TextBody>
        </DisplayText>
      </Anchor>
    </Wrapper>
  );
}

LabelWithTooltip.defaultProps = {
  bgColor: '#57c2e91a',
  fontColor: '#57c2e9',
  labelText: '',
  tooltipText: '',
  width: 340,
  position: 'right',
};

LabelWithTooltip.propTypes = {
  bgColor: PropTypes.string,
  fontColor: PropTypes.string,
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  width: PropTypes.number,
  position: PropTypes.string,
};

export default LabelWithTooltip;
