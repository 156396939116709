import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { theme as Rtheme } from '../Utils/theme';

const FadeIn = keyframes`
  0% {
    opacity :0;
    top: -10px;
    z-index: 1001;
  }
  100% {
    opacity: 1;
    top: 20px;
    z-index: 1001;
  }
`;

const FadeOut = keyframes`
  0% {
    opacity :1;
    z-index: 1001;
  }
  99%{
    z-index: 1001;
  }
  100% {
    opacity :0;
    z-index: 0;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  display: block;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 620px;
  min-height: 100px;
  z-index: 2000;
  animation: ${props =>
    props.isDisplay &&
    css`
      ${FadeIn} 0.5s ease-in forwards;
    `};
  animation: ${props =>
    props.isDisplay === 'fadeOut' &&
    css`
      ${FadeOut} 0.5s ease-in forwards;
    `};
  ${props =>
    !props.isDisplay &&
    `
    opacity :0;
    z-index: 0;
  `}
  @media (max-width: 599px) {
    width: calc(100% - 6%);
  }
`;

const MessageBox = styled.div`
  position: relative;
  box-sizing: border-box;
  display: block;
  top: 0px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 23px 30px 23px 94px;
  border-radius: 6px;
  color: ${Rtheme.colors.white};
  background-color: ${props =>
    props.status === 'positive' && Rtheme.colors.primary};
  background-color: ${props =>
    props.status === 'negative' && Rtheme.colors.bgError};
  background-color: ${props => props.status === 'info' && Rtheme.colors.bgNavy};
  background-color: ${props =>
    props.status === 'warning' && Rtheme.colors.bgWarning};
  box-shadow: 0 10px 25px -2px ${Rtheme.colors.shadow};
  @media (max-width: 599px) {
    height: auto;
    padding-left: 70px;
  }
`;

const IconStatus = styled.div`
  position: absolute;
  box-sizing: border-box;
  display: block;
  left: 23px;
  width: 52px;
  height: 52px;
  border: 4px solid ${Rtheme.colors.white};
  border-radius: 50%;
  opacity: 0.7;
  ${props => {
    if (props.status === ('positive' || 'info' || 'warning')) {
      return `
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 14px;
          left: 13px;
          width: 15px;
          height: 10px;
          border-left: 3px solid ${Rtheme.colors.borderColor};
          border-bottom: 3px solid ${Rtheme.colors.borderColor};
          opacity: 0.7;
          transform: rotate(-45deg);
          @media (max-width: 568px){
            top: 11px;
            left: 10px;
            width: 12px;
            height: 6px;
          }
        }
      `;
    }
    return `
        &:before {
          content: "";
          position: absolute;
          display: block;
          top: 20px;
          right: 7px;
          background-color: ${Rtheme.colors.white};
          width: 30px;
          height: 3px;
          transform: rotate(45deg);
        }
        &:after {
          content: "";
          position: absolute;
          display: block;
          top: 20px;
          right: 7px;
          background-color: ${Rtheme.colors.white};
          width: 30px;
          height: 3px;
          transform: rotate(-45deg);
        }
      `;
  }}
  @media (max-width: 599px) {
    left: 15px;
    width: 42px;
    height: 42px;
    top: 20px;
  }
`;

const MainMessage = styled.p`
  display: block;
  width: 100%;
  margin-bottom: 4px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  margin-top: 5px;
  @media (max-width: 599px) {
    font-size: 15px;
  }
`;

const SubMessage = styled.span`
  min-height: 15px;
  display: block;
  width: 100%;
  font-size: 15px;
  color: #ffffff;
  font-weight: 100;
  opacity: 0.8;
  margin-top: 14px;
  @media (max-width: 599px) {
    font-size: 13px;
  }
`;

const Close = styled.button`
  outline: none;
  position: absolute;
  display: block;
  border: none;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${props => props.status === 'positive' && '#008c88'};
  background-color: ${props => props.status === 'negative' && '#d33338'};
  background-color: ${props => props.status === 'info' && 'navy'};
  background-color: ${props => props.status === 'warning' && 'yellow'};
  color: #ffffff;
  cursor: pointer;
  &:active {
    box-shadow: inset 0px 0px 5px #c1c1c1;
  }
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 16px;
    right: 6px;
    font-size: 20px;
    background-color: #ffffff;
    width: 20px;
    height: 1px;
    transform: rotate(45deg);
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 16px;
    right: 6px;
    font-size: 20px;
    background-color: #ffffff;
    width: 20px;
    height: 1px;
    transform: rotate(-45deg);
  }
`;

export function ResponseMessage({
  responseMessage: { status, isDisplay, message, subMessage },
  onClose,
}) {
  if (!isDisplay) return null;

  return (
    <Wrapper isDisplay={isDisplay}>
      <MessageBox status={status}>
        <IconStatus status={status} />
        <MainMessage>{message}</MainMessage>
        <SubMessage>{subMessage}</SubMessage>
        <Close onClick={onClose} status={status} />
      </MessageBox>
    </Wrapper>
  );
}

ResponseMessage.defaultProps = {
  onClose: () => {},
};

ResponseMessage.propTypes = {
  responseMessage: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onClose: PropTypes.func,
};

// hook for notification
export const useNotification = () => {
  const [notification, setNotification] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const ResponseMessageWithHook = useCallback(() => {
    return (
      <>
        {notification && notification.responseMessage.isDisplay && (
          <ResponseMessage
            responseMessage={notification.responseMessage}
            onClose={notification.onClose}
          />
        )}
      </>
    );
  }, [notification]);

  const notify = ({
    status,
    message,
    subMessage,
    duration,
    isDisplay = true,
  }) => {
    const delay = duration || 5000;
    clearTimeout(timeoutId);
    const id = setTimeout(() => {
      setNotification({
        responseMessage: { status, isDisplay: 'fadeOut', message, subMessage },
      });
      setTimeout(() => {
        setNotification({ responseMessage: { isDisplay: false } });
      }, 500);
      setTimeoutId(null);
    }, delay);
    const onClose = () => {
      setNotification({
        responseMessage: { status, isDisplay: 'fadeOut', message, subMessage },
      });
      setTimeout(() => {
        setNotification({ responseMessage: { isDisplay: false } });
        clearTimeout(timeoutId);
      }, 500);
      clearTimeout(id);
    };
    setNotification({
      responseMessage: { status, isDisplay, message, subMessage },
      onClose,
    });
    setTimeoutId(id);
  };

  return [ResponseMessageWithHook, notify];
};
