import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import { theme as Rtheme } from '../Utils/theme';

const Icon = styled(FontAwesome)`
  width: 13px;
  height: 13px;
  position: relative;
  top: 1px;
  margin-left: -2px;
  color: ${props => (props.color ? props.color : 'default')};
`;

const NewNotifications = styled.span`
  position: absolute;
  padding: 3px 3px;
  border-radius: 50%;
  border: solid 2px white;
  background: red;
`;

const DropDownButton = styled.li`
  display: inline-block;
  margin-left: 20px;
  box-sizing: border-box;
  padding: 10px 0;
  cursor: pointer;
`;

const DropDownContent = styled.div`
  display: ${props => (props.showDropdownOptions ? 'block' : 'none')};
  position: fixed;
  top: ${props => props.top};
  right: ${props => props.right};
  left: ${props => props.left};
  border-radius: 4px;
  border: 1px solid ${Rtheme.colors.border};
  background-color: white;
  box-shadow: 0 8px 16px 0 ${Rtheme.colors.boxShadow};
  box-sizing: border-box;
  z-index: 2;
  width: ${props => props.width ? props.width : 'max-content'};
  padding: 19px;
`;

export function NotificationButton({ items, active, top, right, left, color, activeColor, width }) {
  const [toggle, setToggle] = useState(false);
  const showIconColor = toggle ? activeColor || Rtheme.colors.darkGray : color;
  const iconRef = useRef();

  const handleClickOutside = (event) => {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <DropDownButton onClick={() => setToggle(!toggle)} ref={iconRef}>
      <Icon
        name="fas fa-bell" 
        showDropdownOptions={toggle} 
        color={showIconColor}
      />
      {active && <NewNotifications />}
      {items.length > 0 && (
        <DropDownContent
          showDropdownOptions={toggle}
          top={top}
          right={right}
          left={left}
          width={width}
        >
          {items}
        </DropDownContent>
      )}
    </DropDownButton>
  );
}

NotificationButton.defaultProps = {
  active: false,
  items: [],
  top: '0',
  right: '0',
  left: '0',
  width: 'max-content',
  color: Rtheme.colors.black,
  activeColor: Rtheme.colors.darkGray,
};

NotificationButton.propTypes = {
  active: PropTypes.bool,
  items: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  top: PropTypes.string,
  right: PropTypes.string,
  left: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  activeColor: PropTypes.string,
};
