import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ImgCircle = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  vertical-align: middle;
`;

function NavAccountImg({ imgSrc, imgAlt }) {
  return <ImgCircle src={imgSrc} alt={imgAlt} />;
}

NavAccountImg.defaultProps = {
  imgSrc: undefined,
  imgAlt: undefined,
};

NavAccountImg.propTypes = {
  imgSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imgAlt: PropTypes.string,
};

export default NavAccountImg;
