import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import PropTypes from 'prop-types';
import { Modal, Loader } from '../index';
import { Button, Heading } from '../../../index';
import CrossIcon from '../../Atoms/CrossIcon';

import { Header, LocationModelContainer, Footer, Body } from './Parts';

const Locations = styled.ul`
  padding-bottom: 36px;
`;

const LocationItems = styled.li`
  display: flex;
  min-height: 69px;
  align-items: center;
  border-bottom: 1px solid ${themeGet('colors.shadow')};
  color: ${props =>
    props.disabled
      ? themeGet('colors.fontColorDisabled')
      : themeGet('colors.fontColor')};
  pointer-events: ${props => props.disabled && 'none'};
`;

const LocationTitle = styled.span`
  font-size: 18px;
  flex-grow: 8;
`;

const LocationAction = styled.div`
  flex-grow: 1;
`;

const InfoText = styled.div`
  padding-bottom: 40px;
  color: ${themeGet('colors.fontColorSecondary')};
`;

const HeadingLeftWrapper = styled.div`
  text-align: center;
  flex-grow: 8;
`;
const HeadingRightWrapper = styled.div`
  flex-grow: 1;
  text-align: right;
  cursor: pointer;
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
`;
export function LocationSelectModal(props) {
  const {
    isOpen,
    toggleModal,
    onBackgroundClick,
    onEscapeKeydown,
    modalStyle,
    headingStyle,
    modalTitle,
    isLoading,
    locations,
    locationKey,
    onClickItemButton,
    locationIdKey,
    selectedLocation,
    itemButtonStyle,
    itemDisabledButtonLabel,
    itemButtonLabel,
    footerInfoText,
    onClickFooterButton,
    footerButtonStyle,
    footerButtonLabel,
    isMobileView,
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      onBackgroundClick={onBackgroundClick || toggleModal}
      onEscapeKeydown={onEscapeKeydown || toggleModal}
      height="auto"
      width="622px"
      maxHeight="859px"
      minWidth="500px"
      paddingBottom="30px"
      {...modalStyle}
    >
      <LocationModelContainer>
        <Header>
          <HeadingLeftWrapper>
            <Heading fontWeight="300" size={1} {...headingStyle}>
              {modalTitle}
            </Heading>
          </HeadingLeftWrapper>
          <HeadingRightWrapper>
            <CrossIcon onClick={toggleModal} size="24" />
          </HeadingRightWrapper>
        </Header>
        <Body>
          {isLoading ? (
            <CenteredDiv>
              <Loader />
            </CenteredDiv>
          ) : (
            <Locations>
              {locations.map(item => (
                <LocationItems
                  disabled={
                    isMobileView &&
                    item?.[locationIdKey] === selectedLocation?.[locationIdKey]
                  }
                  onClick={e =>
                    isMobileView && onClickItemButton(e, item, props)
                  }
                  key={item[locationKey]}
                >
                  <LocationTitle>{item[locationKey]}</LocationTitle>
                  <LocationAction>
                    {!isMobileView && (
                      <Button
                        onClick={e => onClickItemButton(e, item, props)}
                        disabled={
                          item?.[locationIdKey] ===
                          selectedLocation?.[locationIdKey]
                        }
                        {...itemButtonStyle}
                      >
                        {item?.[locationIdKey] ===
                        selectedLocation?.[locationIdKey]
                          ? itemDisabledButtonLabel
                          : itemButtonLabel}
                      </Button>
                    )}
                  </LocationAction>
                </LocationItems>
              ))}
            </Locations>
          )}
          <InfoText>{footerInfoText}</InfoText>
          <Footer>
            <Button
              onClick={e => onClickFooterButton(e, props)}
              {...footerButtonStyle}
            >
              {footerButtonLabel}
            </Button>
          </Footer>
        </Body>
      </LocationModelContainer>
    </Modal>
  );
}

LocationSelectModal.defaultProps = {
  modalTitle: 'ビルディングを選択',
  itemButtonLabel: 'を選択',
  itemDisabledButtonLabel: '選ばれた',
  footerButtonLabel: 'ビルディングを選択しない',
  locationKey: 'name',
  locationIdKey: 'id',
  footerInfoText:
    '※ビルディングを選択しない場合は、全ての会議室情報を取得できない場合があります。',
  locations: [
    { id: 1, name: 'ビル１' },
    { id: 2, name: 'ビル２' },
    { id: 3, name: 'ビル3' },
    { id: 4, name: 'ビル4' },
    { id: 5, name: 'ビル5' },
    { id: 6, name: 'ビル6' },
  ],
  onClickItemButton: () => {},
  onClickFooterButton: () => {},
  onBackgroundClick: () => {},
  onEscapeKeydown: () => {},
  toggleModel: () => {},
  modalStyle: {},
  itemButtonStyle: {},
  footerButtonStyle: {},
  headingStyle: {},
  isLoading: undefined,
  selectedLocation: undefined,
};
LocationSelectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  locations: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  itemDisabledButtonLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  modalTitle: PropTypes.string,
  itemButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  footerInfoText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  footerButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  locationKey: PropTypes.string,
  locationIdKey: PropTypes.string,
  onClickItemButton: PropTypes.func,
  onClickFooterButton: PropTypes.func,
  onBackgroundClick: PropTypes.func,
  onEscapeKeydown: PropTypes.func,
  toggleModel: PropTypes.func,
  modalStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  itemButtonStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  footerButtonStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  headingStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool,
  selectedLocation: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
