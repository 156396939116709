import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Input as BaseInput } from '../Atoms';
import { ErrorUI } from './ErrorUI';
import { theme as Rtheme } from '../Utils/theme';

const Wrapper = styled.div`
  height: ${props => props.height || '50px'};
  display: flex;
  width: ${props => props.width || '100%'};
  border: solid 1px ${Rtheme.colors.borderColor};
  border-radius: 5px;
  padding: 0;
  margin-bottom: 5px;
  margin-top: 10px;
  transition: border-color 0.15s ease-in-out;
  &:focus-within {
    border-color: ${Rtheme.colors.borderColorFocus};
  }
`;

const AddonWrapper = styled.div`
  cursor: pointer;
  border-radius: 5px 0px 0px 5px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  background-color: ${props => props.addonBg};
  padding: 0 16px;
`;

const Addon = styled.label`
  cursor: pointer;
  display: inline-table;
  font-size: 13px;
  color: ${props => props.addonColor};
`;

const InputWrapper = styled.div`
  flex-grow: 1;
`;

const Input = styled(BaseInput)`
  border: none;
  outline: none;
`;

function InputWithRightAddon({
  id,
  addonBg,
  addonColor,
  addonText,
  meta,
  ...restProps
}) {
  return (
    <>
      <Wrapper {...restProps}>
        <InputWrapper>
          <Input id={id} {...restProps} />
        </InputWrapper>
        <AddonWrapper addonBg={addonBg}>
          {' '}
          <Addon htmlFor={id} addonColor={addonColor}>
            {addonText}
          </Addon>
        </AddonWrapper>
      </Wrapper>
      <ErrorUI meta={meta} />
    </>
  );
}

InputWithRightAddon.defaultProps = {
  id: undefined,
  placeholder: 'Input',
  addonBg: '#e6f4ef',
  addonColor: '#68878d',
  addonText: 'Right Text',
  meta: { error: undefined, touched: undefined },
};

InputWithRightAddon.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  addonBg: PropTypes.string,
  addonColor: PropTypes.string,
  addonText: PropTypes.string,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export { InputWithRightAddon };
