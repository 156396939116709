import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  fontWeight,
  width,
  minWidth,
  height,
  fontSize,
  color,
  space,
  borders,
  borderRadius,
  position,
  textAlign,
} from 'styled-system';
import { theme as Rtheme } from '../Utils/theme';

export const IconButton = styled.button`
  ${position}
  ${width}
  ${minWidth}
  ${height}
  ${fontSize}
  ${color}
  ${space}
  ${borders}
  ${borderRadius}
  ${textAlign}
  ${fontWeight}
  cursor: pointer;
  &:hover {
    background-color: ${Rtheme.colors.primaryTransparent6};
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: no-drop;
    background: transparent;
    opacity: 0.2;
  }
`;

IconButton.defaultProps = {
  borderRadius: '5px',
  position: 'relative',
  height: '42px',
  color: Rtheme.colors.primary,
  bg: 'white',
  fontWeight: 'bold',
  fontSize: '25px',
  width: '42px',
  border: 'none',
};

IconButton.propTypes = {
  ...position.propTypes,
  ...width.propTypes,
  ...minWidth.propTypes,
  ...height.propTypes,
  ...color.propTypes,
  ...space.propTypes,
  ...borders.propTypes,
  ...textAlign.propTypes,
  ...fontWeight.propTypes,
  border: PropTypes.string,
  submitFailed: PropTypes.bool,
};
