import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { theme as Rtheme } from '../Utils/theme';

const Wrapper = styled.div`
  margin-bottom: 5px;
  .rc-time-picker-input {
    cursor: pointer;
    box-sizing: border-box;
    height: 44px;
    width: 84px;
    border-radius: 5px;
    border: solid 1px ${Rtheme.colors.borderColor};
    font-size: 15px;
    color: ${Rtheme.colors.fontColor};
    outline: 0;
    padding-left: 20px;
    transition: border-color 0.15s ease-in-out;
  }
`;

function RenderTimePicker(props) {
  const {
    input: { value: inputValue, onChange },
    defaultValue,
    use12Hours,
    onClose,
    minTime,
    maxTime,
    className,
    showMinute,
    minuteStep,
  } = props;

  const [time, setTime] = useState();

  useEffect(() => {
    setTime(moment(inputValue, 'HH:mm'));
  }, [inputValue]);

  const handleClose = () => {
    if (minTime && moment(time).isBefore(moment(minTime, 'HH:mm'))) {
      onChange(minTime);
      onClose(minTime);
      setTime(moment(minTime, 'HH:mm'));
    } else if (maxTime && moment(time).isAfter(moment(maxTime, 'HH:mm'))) {
      onChange(maxTime);
      onClose(maxTime);
      setTime(moment(maxTime, 'HH:mm'));
    } else {
      onChange(moment(time).format('HH:mm'));
      onClose(moment(time).format('HH:mm'));
      setTime(time);
    }
  };

  const handleChange = value => {
    setTime(value);
  };

  return (
    <Wrapper>
      <TimePicker
        {...props}
        className={className}
        value={inputValue ? time : moment()}
        defaultValue={defaultValue ? moment(defaultValue) : null}
        onClose={handleClose}
        onChange={handleChange}
        showMinute={showMinute}
        showSecond={false}
        minuteStep={minuteStep}
        use12Hours={use12Hours}
        format="HH:mm"
        hideDisabledOptions
        inputReadOnly
        clearIcon={<></>}
      />
    </Wrapper>
  );
}

RenderTimePicker.defaultProps = {
  use12Hours: false,
  input: { value: undefined, onChange: () => {} },
  defaultValue: undefined,
  onClose: () => {},
  minTime: undefined,
  maxTime: undefined,
  className: undefined,
  showMinute: undefined,
  minuteStep: 15,
};

RenderTimePicker.propTypes = {
  use12Hours: PropTypes.bool,
  input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  defaultValue: PropTypes.string,
  onClose: PropTypes.func,
  minTime: PropTypes.string,
  maxTime: PropTypes.string,
  className: PropTypes.string,
  showMinute: PropTypes.bool,
  minuteStep: PropTypes.number,
};

export default RenderTimePicker;
