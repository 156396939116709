import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InfiniteScrollWrapper = styled.div`
  height: 100%;
`;
function InfiniteScroll({
  children,
  fetchMoreData,
  currentPage,
  totalPages,
  loadMore,
  isLoading,
}) {
  const [page, setPage] = useState(currentPage);
  const refetch = page <= totalPages;
  const loader = useRef(null);

  const handleObserver = useCallback(
    entities => {
      const target = entities[0];
      if (target.isIntersecting) {
        setPage(page => page + 1);
      }
    },

    [],
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1,
    };
    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);

  useEffect(() => {
    if (page !== 1 && page <= totalPages) {
      fetchMoreData(page);
    }
  }, [page]);

  return (
    <InfiniteScrollWrapper>
      <div className="wrapper">
        {children}
        <div className="loading" ref={loader}>
          <h2>{(refetch || isLoading) && loadMore}</h2>
        </div>
      </div>
    </InfiniteScrollWrapper>
  );
}
InfiniteScroll.defaultProps = {
  fetchMoreData: () => {},
  currentPage: 0,
  totalPages: 0,
  children: undefined,
  loadMore: 'Loading...',
};
InfiniteScroll.propTypes = {
  fetchMoreData: PropTypes.func,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  loadMore: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
export default InfiniteScroll;
